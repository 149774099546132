"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@chakra-ui/react");
const react_2 = require("react");
function useFiltersModal(initialFilters, reinitializeArray) {
    const disclosure = (0, react_1.useDisclosure)();
    const [filters, setFilters] = (0, react_2.useState)(initialFilters);
    (0, react_2.useEffect)(() => {
        setFilters(initialFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, reinitializeArray);
    return { ...disclosure, filters, setFilters };
}
exports.default = useFiltersModal;
