"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GanttSegmentType = exports.emptyGanttData = exports.emptyBothFlightList = exports.emptyListData = void 0;
const gantt_1 = require("@/constants/gantt");
exports.emptyListData = {
    count: 0,
    items: [],
};
exports.emptyBothFlightList = {
    arrivalFlights: exports.emptyListData,
    departureFlights: exports.emptyListData,
};
exports.emptyGanttData = [
    {
        terminal: {
            id: null,
            code: null,
            type: 'not_allocated',
        },
        data: [
            {
                stand: {
                    [gantt_1.GanttStandFields.id]: null,
                    [gantt_1.GanttStandFields.position]: 0,
                    [gantt_1.GanttStandFields.type]: 'not-allocated',
                    [gantt_1.GanttStandFields.typeRepresentation]: 'N',
                    [gantt_1.GanttStandFields.isAvailable]: true,
                },
                data: [],
            },
        ],
    },
];
var GanttSegmentType;
(function (GanttSegmentType) {
    GanttSegmentType["construction"] = "construction";
    GanttSegmentType["aircraftTowing"] = "towing_movement";
    GanttSegmentType["flight"] = "flight";
})(GanttSegmentType = exports.GanttSegmentType || (exports.GanttSegmentType = {}));
