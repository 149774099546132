"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildArrayWithNumberOfItems = exports.removeArrayIndex = void 0;
const removeArrayIndex = (array, indexToRemove) => [
    ...array.slice(0, indexToRemove),
    ...array.slice(indexToRemove + 1, array.length),
];
exports.removeArrayIndex = removeArrayIndex;
const buildArrayWithNumberOfItems = (count) => [...Array(count).keys()];
exports.buildArrayWithNumberOfItems = buildArrayWithNumberOfItems;
