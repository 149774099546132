"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmbuliftRequestsSection = exports.getAmbuliftCardFieldNames = void 0;
const react_1 = require("@chakra-ui/react");
const react_2 = __importStar(require("react"));
const components_1 = require("@/components");
const types_1 = require("@/components/card-pnae-assistance-request/types");
const flight_1 = require("@/constants/flight");
function getAmbuliftCardFieldNames(prefix) {
    if (!prefix) {
        return {
            [types_1.AmbuliftRequestFields.fulfilledAt]: types_1.AmbuliftRequestFields.fulfilledAt,
            [types_1.AmbuliftRequestFields.pnaeType]: types_1.AmbuliftRequestFields.pnaeType,
            [types_1.AmbuliftRequestFields.scheduledAt]: types_1.AmbuliftRequestFields.scheduledAt,
            [types_1.AmbuliftRequestFields.status]: types_1.AmbuliftRequestFields.status,
            [types_1.AmbuliftRequestFields.id]: types_1.AmbuliftRequestFields.id,
        };
    }
    return {
        [types_1.AmbuliftRequestFields.fulfilledAt]: `${prefix}.${types_1.AmbuliftRequestFields.fulfilledAt}`,
        [types_1.AmbuliftRequestFields.pnaeType]: `${prefix}.${types_1.AmbuliftRequestFields.pnaeType}`,
        [types_1.AmbuliftRequestFields.scheduledAt]: `${prefix}.${types_1.AmbuliftRequestFields.scheduledAt}`,
        [types_1.AmbuliftRequestFields.status]: `${prefix}.${types_1.AmbuliftRequestFields.status}`,
        [types_1.AmbuliftRequestFields.id]: `${prefix}.${types_1.AmbuliftRequestFields.id}`,
    };
}
exports.getAmbuliftCardFieldNames = getAmbuliftCardFieldNames;
const AmbuliftRequestsSection = (props) => {
    var _a;
    const { values, errors, issues, touched, setFieldValue, fieldOptions, user, isFieldDisabled, onOpenChatModal, } = props;
    const ambuliftRequests = values === null || values === void 0 ? void 0 : values[flight_1.FlightFieldNames.ambuliftRequests];
    const sortedAmbuliftRequests = (0, react_2.useMemo)(() => ambuliftRequests === null || ambuliftRequests === void 0 ? void 0 : ambuliftRequests.sort((itemA, itemB) => itemA.id - itemB.id), [ambuliftRequests]);
    const ambuliftRequestFieldNames = (0, react_2.useMemo)(() => sortedAmbuliftRequests === null || sortedAmbuliftRequests === void 0 ? void 0 : sortedAmbuliftRequests.map((_, index) => {
        const fieldNamesPrefix = `${flight_1.FlightFieldNames.ambuliftRequests}[${index}]`;
        return getAmbuliftCardFieldNames(fieldNamesPrefix);
    }), [sortedAmbuliftRequests]);
    const isAmbuliftFieldDisabled = (fieldName) => fieldName
        ? isFieldDisabled(`${flight_1.FlightFieldNames.ambuliftRequests}__${fieldName}`)
        : isFieldDisabled(flight_1.FlightFieldNames.ambuliftRequests);
    return (react_2.default.createElement(react_1.VStack, { spacing: 4, align: "stretch", width: "100%" }, (_a = sortedAmbuliftRequests === null || sortedAmbuliftRequests === void 0 ? void 0 : sortedAmbuliftRequests.map) === null || _a === void 0 ? void 0 : _a.call(sortedAmbuliftRequests, (item, index) => {
        var _a, _b, _c, _d;
        const [requestErrors, requestIssues, requestTouched] = [
            (_a = errors === null || errors === void 0 ? void 0 : errors[flight_1.FlightFieldNames.ambuliftRequests]) === null || _a === void 0 ? void 0 : _a[index],
            (_c = (_b = issues === null || issues === void 0 ? void 0 : issues[flight_1.FlightFieldNames.ambuliftRequestNestedField]) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c[item.id],
            (_d = touched === null || touched === void 0 ? void 0 : touched[flight_1.FlightFieldNames.ambuliftRequests]) === null || _d === void 0 ? void 0 : _d[index],
        ];
        return (react_2.default.createElement(components_1.AmbuliftRequestCardWrapper, { key: item.id, fieldIndex: index, requestData: item, setFieldValue: setFieldValue, fieldOptions: fieldOptions, fieldNames: ambuliftRequestFieldNames[index], errors: requestErrors, issues: requestIssues, touched: requestTouched, user: user, isFieldDisabled: isAmbuliftFieldDisabled, onOpenChatModal: onOpenChatModal }));
    })));
};
exports.AmbuliftRequestsSection = AmbuliftRequestsSection;
exports.default = exports.AmbuliftRequestsSection;
