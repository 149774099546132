"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useIsOverflow = (props) => {
    const { ref, isHorizontalOverflow = true, triggerRevaluation, callback } = props;
    (0, react_1.useLayoutEffect)(() => {
        const { current } = ref;
        if (!current)
            return;
        const hasOverflow = isHorizontalOverflow
            ? (current === null || current === void 0 ? void 0 : current.scrollWidth) > (current === null || current === void 0 ? void 0 : current.clientWidth)
            : (current === null || current === void 0 ? void 0 : current.scrollHeight) > (current === null || current === void 0 ? void 0 : current.clientHeight);
        if (callback)
            callback(hasOverflow);
    }, [callback, ref, triggerRevaluation, isHorizontalOverflow]);
};
exports.default = useIsOverflow;
