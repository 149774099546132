"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatActivityFilters = exports.formatTemplateFilters = exports.formatTemplateFields = exports.formatScheduleFilters = exports.formatScheduleTemplate = exports.makeFormatter = void 0;
const reports_1 = require("@/constants/reports");
const types_d_1 = require("@/screens/reports-screen/types.d");
const date_1 = require("@/services/date");
const getItemValue = (item) => item === null || item === void 0 ? void 0 : item.value;
const getItemId = (item) => item.id;
const toMap = (mapper) => (collection) => collection === null || collection === void 0 ? void 0 : collection.map(mapper);
const noopFormatter = (value) => value;
const makeFormatter = (formattersRecord) => (formValues) => {
    const entries = Object.entries(formValues);
    const formattedEntries = entries.map(([key, value]) => {
        var _a;
        const formatter = (_a = formattersRecord[key]) !== null && _a !== void 0 ? _a : noopFormatter;
        return [key, formatter(value, formValues)];
    });
    const formatterFilters = Object.fromEntries(formattedEntries);
    return formatterFilters;
};
exports.makeFormatter = makeFormatter;
const scheduleTemplateFormatters = {
    [reports_1.ReportScheduleFieldNames.preset]: getItemValue,
    [reports_1.ReportScheduleFieldNames.weekday]: getItemValue,
    [reports_1.ReportScheduleFieldNames.day]: getItemValue,
    [reports_1.ReportScheduleFieldNames.emails]: toMap(getItemId),
    [reports_1.ReportScheduleFieldNames.reportType]: getItemValue,
};
const formatScheduleTemplate = (schedule) => {
    const formatter = (0, exports.makeFormatter)(scheduleTemplateFormatters);
    const hourMinuteValue = (0, date_1.getHourMinuteSpited)(schedule[reports_1.ReportScheduleFieldNames.hour]);
    const scheduleForm = {
        ...schedule,
        timezone: (0, date_1.getTimezone)(),
        [reports_1.ReportScheduleFieldNames.minute]: hourMinuteValue.minute,
        [reports_1.ReportScheduleFieldNames.hour]: hourMinuteValue.hour,
    };
    return formatter(scheduleForm);
};
exports.formatScheduleTemplate = formatScheduleTemplate;
exports.formatScheduleFilters = (0, exports.makeFormatter)(scheduleTemplateFormatters);
const templateFieldFormatters = {
    [reports_1.ReportTemplateFieldNames.period]: getItemValue,
    [reports_1.ReportTemplateFieldNames.routeType]: getItemValue,
    [reports_1.ReportTemplateFieldNames.airports]: toMap(getItemId),
    [reports_1.ReportTemplateFieldNames.airlines]: toMap(getItemId),
    [reports_1.ReportTemplateFieldNames.flightTypes]: toMap(getItemId),
    [reports_1.ReportTemplateFieldNames.serviceTypes]: toMap(getItemId),
    [reports_1.ReportTemplateFieldNames.fileFormats]: toMap(getItemValue),
    [reports_1.ReportTemplateFieldNames.schedules]: toMap(exports.formatScheduleTemplate),
    [reports_1.ReportTemplateFieldNames.startDate]: date_1.convertDateToApiFormat,
    [reports_1.ReportTemplateFieldNames.endDate]: date_1.convertDateToApiFormat,
};
exports.formatTemplateFields = (0, exports.makeFormatter)(templateFieldFormatters);
const templateFilterFormatters = {
    [reports_1.ReportTemplateFieldNames.period]: toMap(getItemValue),
    [reports_1.ReportTemplateFieldNames.routeType]: toMap(getItemValue),
    [reports_1.ReportTemplateFieldNames.airports]: toMap(getItemId),
    [reports_1.ReportTemplateFieldNames.airlines]: toMap(getItemId),
    [reports_1.ReportTemplateFieldNames.flightTypes]: toMap(getItemId),
    [reports_1.ReportTemplateFieldNames.serviceTypes]: toMap(getItemId),
    [reports_1.ReportTemplateFieldNames.fileFormats]: toMap(getItemValue),
};
exports.formatTemplateFilters = (0, exports.makeFormatter)(templateFilterFormatters);
const activityfilterFormatters = {
    [types_d_1.ActivitiesFilterKey.REPORT_TYPE]: value => getItemValue(value),
    [types_d_1.ActivitiesFilterKey.FROM]: value => (0, date_1.convertDateToApiFormat)(value),
    [types_d_1.ActivitiesFilterKey.TO]: value => (0, date_1.convertDateToApiFormat)(value),
    [types_d_1.ActivitiesFilterKey.STATUS]: value => getItemValue(value),
};
exports.formatActivityFilters = (0, exports.makeFormatter)(activityfilterFormatters);
