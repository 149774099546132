"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const date_1 = require("@/services/date");
function usePaddedDateRange(dateRange, setDateRange, intervalPaddingSize) {
    const { startDate, endDate } = dateRange;
    const [{ paddedRangeStart, paddedRangeEnd }, setLoadedDateRange] = (0, react_1.useState)(() => ({
        paddedRangeStart: (0, date_1.subtractHoursFromDatetime)(startDate, intervalPaddingSize),
        paddedRangeEnd: (0, date_1.addHoursToDatetime)(endDate, intervalPaddingSize),
    }));
    (0, react_1.useEffect)(() => {
        if (startDate.getTime() <= paddedRangeStart.getTime() ||
            endDate.getTime() >= paddedRangeEnd.getTime()) {
            setLoadedDateRange({
                paddedRangeStart: (0, date_1.subtractHoursFromDatetime)(startDate, intervalPaddingSize),
                paddedRangeEnd: (0, date_1.addHoursToDatetime)(endDate, intervalPaddingSize),
            });
        }
    }, [endDate, intervalPaddingSize, paddedRangeEnd, paddedRangeStart, startDate]);
    const moveDateRangeForward = (0, react_1.useCallback)((increment) => {
        setDateRange({
            startDate: (0, date_1.addHoursToDatetime)(startDate, increment),
            endDate: (0, date_1.addHoursToDatetime)(endDate, increment),
        });
    }, [endDate, startDate, setDateRange]);
    const moveDateRangeBackward = (0, react_1.useCallback)((decrement) => {
        setDateRange({
            startDate: (0, date_1.subtractHoursFromDatetime)(startDate, decrement),
            endDate: (0, date_1.subtractHoursFromDatetime)(endDate, decrement),
        });
    }, [endDate, startDate, setDateRange]);
    return {
        paddedDateRange: { startDate: paddedRangeStart, endDate: paddedRangeEnd },
        setDateRange,
        moveDateRangeBackward,
        moveDateRangeForward,
    };
}
exports.default = usePaddedDateRange;
