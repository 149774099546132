"use strict";
/* eslint-disable no-param-reassign */
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTowingPayloadBeforeSubmit = exports.formatPayloadBeforeSubmit = exports.formatUnsavedPnaeRequests = exports.formatAmbuliftRequestDates = void 0;
const types_1 = require("@/components/card-pnae-assistance-request/types");
const change_handlers_1 = require("@/components/list-flight/change-handlers");
const flight_1 = require("@/constants/flight");
const towing_1 = require("@/constants/towing");
const date_1 = require("@/services/date");
const adjustViasFlightLeg = (values, flightId) => {
    var _a;
    if (!((_a = values === null || values === void 0 ? void 0 : values[flight_1.FlightFieldNames.vias]) === null || _a === void 0 ? void 0 : _a.length))
        return;
    const allVias = values === null || values === void 0 ? void 0 : values[flight_1.FlightFieldNames.vias];
    const nonEmptyVias = allVias.filter((i) => i);
    nonEmptyVias.forEach((via, index) => {
        // eslint-disable-next-line camelcase
        const newVia = { ...via, flight_leg: index + 1, flight: flightId };
        nonEmptyVias[index] = newVia;
    });
    values[flight_1.FlightFieldNames.vias] = nonEmptyVias;
};
const formatAmbuliftRequestDates = (values) => {
    var _a;
    const formattedRequests = (_a = values[flight_1.FlightFieldNames.ambuliftRequests]) === null || _a === void 0 ? void 0 : _a.map((ambuliftRequest) => ({
        ...ambuliftRequest,
        [types_1.AmbuliftRequestFields.scheduledAt]: (0, date_1.convertDateToApiFormat)(ambuliftRequest[types_1.AmbuliftRequestFields.scheduledAt]),
        [types_1.AmbuliftRequestFields.fulfilledAt]: (0, date_1.convertDateToApiFormat)(ambuliftRequest[types_1.AmbuliftRequestFields.fulfilledAt]),
    }));
    values[flight_1.FlightFieldNames.ambuliftRequests] = formattedRequests;
    return values;
};
exports.formatAmbuliftRequestDates = formatAmbuliftRequestDates;
const formatUnsavedPnaeRequests = (values) => {
    var _a;
    const formattedRequests = (_a = values[flight_1.FlightFieldNames.unsavedRequests]) === null || _a === void 0 ? void 0 : _a.map((unsavedRequest) => {
        const requestCopy = { ...unsavedRequest };
        if (requestCopy[types_1.PnaeFieldNames.hasAmbulift]) {
            delete requestCopy[types_1.PnaeFieldNames.airportResource];
            requestCopy[types_1.PnaeFieldNames.scheduledAt] = (0, date_1.convertDateToApiFormat)(unsavedRequest[types_1.PnaeFieldNames.scheduledAt]);
        }
        else {
            delete requestCopy[types_1.PnaeFieldNames.fulfilledAt];
            delete requestCopy[types_1.PnaeFieldNames.scheduledAt];
            delete requestCopy[types_1.PnaeFieldNames.status];
        }
        return requestCopy;
    });
    values[flight_1.FlightFieldNames.unsavedRequests] = formattedRequests;
    return values;
};
exports.formatUnsavedPnaeRequests = formatUnsavedPnaeRequests;
const formatPayloadBeforeSubmit = (values, flightId = undefined) => {
    values = (0, date_1.convertDatesToApiFormat)(values, [
        flight_1.FlightFieldNames.scheduledArrivalDatetime,
        flight_1.FlightFieldNames.scheduledDepartureDatetime,
        flight_1.FlightFieldNames.currentEstimatedTime,
        flight_1.FlightFieldNames.actualTouchdownTime,
        flight_1.FlightFieldNames.actualInBlockTime,
        flight_1.FlightFieldNames.actualOffBlockTime,
        flight_1.FlightFieldNames.actualTakeOffTime,
        flight_1.FlightFieldNames.openTimeGate,
        flight_1.FlightFieldNames.closeTimeGate,
        flight_1.FlightFieldNames.beltOpenDatetime,
        flight_1.FlightFieldNames.beltCloseDatetime,
        flight_1.FlightFieldNames.blockedAt,
    ]);
    values = (0, exports.formatUnsavedPnaeRequests)(values);
    values = (0, exports.formatAmbuliftRequestDates)(values);
    adjustViasFlightLeg(values, flightId);
    values[flight_1.FlightFieldNames.passengersCount] = (0, change_handlers_1.getPassengersCountPayload)(values[flight_1.FlightFieldNames.passengersCount]);
    if (values.identity)
        delete values.identity;
    return values;
};
exports.formatPayloadBeforeSubmit = formatPayloadBeforeSubmit;
const formatTowingPayloadBeforeSubmit = (values) => {
    values = (0, date_1.convertDatesToApiFormat)(values, [
        towing_1.TowingFields.ENTRANCE_DATETIME,
        towing_1.TowingFields.EXIT_DATETIME,
    ]);
    return values;
};
exports.formatTowingPayloadBeforeSubmit = formatTowingPayloadBeforeSubmit;
