"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAirlineOptions = void 0;
const react_1 = require("react");
const flight_1 = require("@/constants/flight");
const emptySortedAirlineOptions = { generalAviationAirlines: [], regularAirlines: [] };
const formatAirlineOptions = (airlineOption) => ({
    ...airlineOption,
    ...airlineOption.airline,
    airline: undefined,
});
const sortAirlineOptions = (acc, airline) => {
    const isGeneralAviationAirline = airline.icao_code === flight_1.generalAviationIcao;
    const airlineKey = isGeneralAviationAirline ? 'generalAviationAirlines' : 'regularAirlines';
    return { ...acc, [airlineKey]: [...acc[airlineKey], airline] };
};
const useAirlineOptions = (params) => {
    const { allAirlineOptions } = params;
    const sortedAirlines = (0, react_1.useMemo)(() => {
        var _a;
        return (_a = allAirlineOptions === null || allAirlineOptions === void 0 ? void 0 : allAirlineOptions.map(formatAirlineOptions).reduce(sortAirlineOptions, emptySortedAirlineOptions)) !== null && _a !== void 0 ? _a : emptySortedAirlineOptions;
    }, [allAirlineOptions]);
    return sortedAirlines;
};
exports.useAirlineOptions = useAirlineOptions;
exports.default = exports.useAirlineOptions;
