"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatNotificationContext = exports.initialChatContextData = void 0;
const react_1 = require("react");
exports.initialChatContextData = {
    notifications: {},
    notificationsDispatch: () => ({}),
};
exports.ChatNotificationContext = (0, react_1.createContext)(exports.initialChatContextData);
function useChatNotifications() {
    return (0, react_1.useContext)(exports.ChatNotificationContext);
}
exports.default = useChatNotifications;
