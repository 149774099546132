"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IssuesListApiFields = exports.UserIssueSettingField = exports.UserSettingsFields = exports.FilterKeys = exports.BaseFilterKeys = void 0;
/* eslint-disable camelcase */
const dashboard_1 = require("@/constants/dashboard");
var BaseFilterKeys;
(function (BaseFilterKeys) {
    BaseFilterKeys["startDate"] = "start_date";
    BaseFilterKeys["endDate"] = "end_date";
    BaseFilterKeys["arrival"] = "arrival_flights";
    BaseFilterKeys["departure"] = "departure_flights";
})(BaseFilterKeys = exports.BaseFilterKeys || (exports.BaseFilterKeys = {}));
exports.FilterKeys = { ...BaseFilterKeys, ...dashboard_1.IssueType };
var UserSettingsFields;
(function (UserSettingsFields) {
    UserSettingsFields["automaticRefreshScreenTimeInMinutes"] = "automatic_refresh_screen_time";
    UserSettingsFields["issues"] = "issues";
})(UserSettingsFields = exports.UserSettingsFields || (exports.UserSettingsFields = {}));
var UserIssueSettingField;
(function (UserIssueSettingField) {
    UserIssueSettingField["issueType"] = "issue_type";
    UserIssueSettingField["isDisplayed"] = "is_displayed";
    UserIssueSettingField["priority"] = "priority";
})(UserIssueSettingField = exports.UserIssueSettingField || (exports.UserIssueSettingField = {}));
var IssuesListApiFields;
(function (IssuesListApiFields) {
    IssuesListApiFields["flights"] = "flights";
    IssuesListApiFields["issuesNumbers"] = "issues_numbers";
})(IssuesListApiFields = exports.IssuesListApiFields || (exports.IssuesListApiFields = {}));
