"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TowerFieldNames = void 0;
var TowerFieldNames;
(function (TowerFieldNames) {
    TowerFieldNames["id"] = "id";
    TowerFieldNames["identity"] = "identity";
    TowerFieldNames["scheduledDateTime"] = "scheduled_datetime";
    TowerFieldNames["aircraftRegistration"] = "aircraft_registration";
    TowerFieldNames["aircraftType"] = "aircraft_type";
    TowerFieldNames["inBlock"] = "actual_in_block_datetime";
    TowerFieldNames["offBlock"] = "actual_off_block_datetime";
    TowerFieldNames["stand"] = "stand";
    TowerFieldNames["reclaimBelt"] = "reclaim_belt_carrousel";
    TowerFieldNames["gate"] = "gate";
    TowerFieldNames["originAirport"] = "origin_airport";
    TowerFieldNames["destinationAirport"] = "destination_airport";
    TowerFieldNames["serviceType"] = "service_type";
    TowerFieldNames["pax"] = "passengers_count";
    TowerFieldNames["flightStatus"] = "current_status";
    TowerFieldNames["hasLinkedFlight"] = "has_linked_flight";
})(TowerFieldNames = exports.TowerFieldNames || (exports.TowerFieldNames = {}));
