"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@chakra-ui/react");
const react_2 = require("react");
const flight_1 = require("@/constants/flight");
const userPermission_1 = require("@/constants/userPermission");
const flightDetails_1 = require("@/services/flightDetails");
const useAircraftTypeModal = (user) => {
    var _a, _b;
    const canCreateAircraftType = (_a = user === null || user === void 0 ? void 0 : user.permissions) === null || _a === void 0 ? void 0 : _a.userHasPermission(userPermission_1.PermissionModel.AIRCRAFT_TYPE, userPermission_1.PermissionAction.AIRCRAFT_TYPE_ADD);
    const canEditAircraftType = (_b = user === null || user === void 0 ? void 0 : user.permissions) === null || _b === void 0 ? void 0 : _b.userHasPermission(userPermission_1.PermissionModel.AIRCRAFT_TYPE, userPermission_1.PermissionAction.AIRCRAFT_TYPE_CHANGE);
    const createAircraftTypeDisclosure = (0, react_1.useDisclosure)();
    const editAircraftTypeDisclosure = (0, react_1.useDisclosure)();
    const [editableAircraftType, setEditableAircraftType] = (0, react_2.useState)();
    const [triggerResetAircraftTypePagination, setTriggerResetAircraftTypePagination] = (0, react_2.useState)(false);
    const handleOnCreateAircraftTypeOption = (0, react_2.useCallback)(() => {
        if (canCreateAircraftType) {
            createAircraftTypeDisclosure.onOpen();
        }
    }, [user]);
    const handleOnOpenAircraftTypeEditModal = (0, react_2.useCallback)((values) => (0, flightDetails_1.loadSingleFlightFieldOption)(flight_1.FieldOptions.AIRCRAFT_TYPE, values === null || values === void 0 ? void 0 : values[flight_1.FlightFieldNames.aircraftType]).then(response => {
        editAircraftTypeDisclosure.onOpen();
        setEditableAircraftType(response.data);
    }), [user]);
    const handleOnCloseAircraftTypeEditModal = (0, react_2.useCallback)(() => {
        setTriggerResetAircraftTypePagination(true);
        editAircraftTypeDisclosure.onClose();
        setTriggerResetAircraftTypePagination(false);
    }, [user]);
    const handleAircraftTypeChange = (0, react_2.useCallback)((aircraftType, setFieldValue) => {
        var _a;
        setFieldValue(flight_1.FlightFieldNames.aircraftType, (_a = aircraftType === null || aircraftType === void 0 ? void 0 : aircraftType.id) !== null && _a !== void 0 ? _a : null);
    }, [user]);
    return {
        canCreateAircraftType,
        canEditAircraftType,
        createAircraftTypeDisclosure,
        editAircraftTypeDisclosure,
        editableAircraftType,
        triggerResetAircraftTypePagination,
        handleOnCreateAircraftTypeOption,
        handleOnOpenAircraftTypeEditModal,
        handleOnCloseAircraftTypeEditModal,
        handleAircraftTypeChange,
    };
};
exports.default = useAircraftTypeModal;
