"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapStringToFlightChatThreadType = exports.ChatThreadType = exports.ChatThreadRequestStatus = exports.PLACEHOLDER_THREAD_ID = exports.PLACEHOLDER_GENERAL_THREAD = exports.MessageMetadataFields = exports.ChatThreadStatus = exports.ChatThreadFields = void 0;
var ChatThreadFields;
(function (ChatThreadFields) {
    ChatThreadFields["id"] = "id";
    ChatThreadFields["vendorId"] = "thread_id";
    ChatThreadFields["threadType"] = "thread_type";
    ChatThreadFields["name"] = "thread_name";
    ChatThreadFields["flight"] = "flight";
    ChatThreadFields["flightAirline"] = "flight_airline";
    ChatThreadFields["status"] = "thread_status";
    ChatThreadFields["mostRecentTimestamp"] = "most_recent_message_timestamp";
    ChatThreadFields["threadDisplayName"] = "thread_display_name";
    ChatThreadFields["ambuliftRequestId"] = "ambulift_request_id";
    ChatThreadFields["ambuliftRequestStatus"] = "ambulift_request_status";
    ChatThreadFields["generalRequestStatus"] = "general_request_status";
    ChatThreadFields["hybridDisembarkRequestStatus"] = "hybrid_disembark_request_status";
    ChatThreadFields["chatThreadRequest"] = "chat_thread_request";
})(ChatThreadFields = exports.ChatThreadFields || (exports.ChatThreadFields = {}));
var ChatThreadStatus;
(function (ChatThreadStatus) {
    ChatThreadStatus["active"] = "active";
    ChatThreadStatus["closed"] = "closed";
})(ChatThreadStatus = exports.ChatThreadStatus || (exports.ChatThreadStatus = {}));
var MessageMetadataFields;
(function (MessageMetadataFields) {
    MessageMetadataFields["isSystemMessage"] = "is_system_message";
    MessageMetadataFields["isInitialMessage"] = "is_initial_message";
    MessageMetadataFields["triggeredBy"] = "triggered_by";
    MessageMetadataFields["scheduledAt"] = "scheduled_at";
    MessageMetadataFields["fullfilledAt"] = "fulfilled_at";
    MessageMetadataFields["isClosureWarning"] = "is_closure_warning";
    MessageMetadataFields["isReopeningWarning"] = "is_reopening_warning";
    MessageMetadataFields["closureReason"] = "closure_reason";
})(MessageMetadataFields = exports.MessageMetadataFields || (exports.MessageMetadataFields = {}));
exports.PLACEHOLDER_GENERAL_THREAD = 'PLACEHOLDER_GENERAL_THREAD';
exports.PLACEHOLDER_THREAD_ID = 'PLACEHOLDER_ID';
var ChatThreadRequestStatus;
(function (ChatThreadRequestStatus) {
    ChatThreadRequestStatus["PENDING"] = "pending";
    ChatThreadRequestStatus["APPROVED"] = "approved";
    ChatThreadRequestStatus["CANCELLED"] = "cancelled";
    ChatThreadRequestStatus["FINISHED"] = "finished";
})(ChatThreadRequestStatus = exports.ChatThreadRequestStatus || (exports.ChatThreadRequestStatus = {}));
var ChatThreadType;
(function (ChatThreadType) {
    ChatThreadType["AMBULIFT"] = "ambulift";
    ChatThreadType["GENERAL"] = "general";
    ChatThreadType["HYBRID_DISEMBARK"] = "hybrid_disembark";
})(ChatThreadType = exports.ChatThreadType || (exports.ChatThreadType = {}));
function mapStringToFlightChatThreadType(threadType) {
    switch (threadType) {
        case 'ambulift':
            return ChatThreadType.AMBULIFT;
        case 'general':
            return ChatThreadType.GENERAL;
        case 'hybrid_disembark':
            return ChatThreadType.HYBRID_DISEMBARK;
        default:
            return ChatThreadType.GENERAL;
    }
}
exports.mapStringToFlightChatThreadType = mapStringToFlightChatThreadType;
