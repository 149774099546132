"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useFieldWarnings(errors, issues) {
    return (0, react_1.useMemo)(() => {
        if (!issues)
            if (!errors)
                return {};
            else
                return errors;
        const mergedFields = { ...(errors !== null && errors !== void 0 ? errors : {}) };
        Object.keys(issues).forEach(key => {
            mergedFields[key] = issues[key];
        });
        return mergedFields;
    }, [errors, issues]);
}
exports.default = useFieldWarnings;
