"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable max-lines */
const Ids = {
    editFlight: {
        buttons: {
            linkFlight: 'edit-flight-link-flight-button',
        },
    },
};
exports.default = Ids;
