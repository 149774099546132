"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const formatQueryString = (params) => Object.entries(params)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => value !== undefined && String(value) !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
const formatPageRangeQuery = (startPage, endPage) => startPage && endPage && startPage > 0 && endPage > 0
    ? `?startPage=${startPage}&endPage=${endPage}`
    : '';
const ApiUrls = {
    Authorization: {
        GetToken: '/token/',
        RefreshToken: '/refresh/',
        BlacklistToken: '/blacklist/',
        ValidateToken: '/auth/',
    },
    airport: {
        settings: (airportCode) => `/airports/${airportCode}/settings/`,
        createAirport: () => `/airport/create`,
        byIcao: (icaoCode) => `/airport/${icaoCode}`,
    },
    aircraft: {
        createAircraft: () => `/aircraft/create`,
        editAircraft: (aircraftId) => `/aircraft/edit/${aircraftId}`,
    },
    aircraftType: {
        createAircraftType: () => `/aircraft_type/create`,
        editAircraftType: (aircraftTypeId) => `/aircraft_type/edit/${aircraftTypeId}`,
    },
    Navbar: {
        GetUserInfo: '/userInfo/',
    },
    flights: {
        patch: (flightId, isArrivalOrDepartureFlag) => `/flights/${isArrivalOrDepartureFlag}/${flightId}`,
        create: '/flights/create',
        validate: '/flights/validate',
        pairs: (flightId, isArrivalOrDepartureFlag) => `flights/pairs/${isArrivalOrDepartureFlag}/${flightId}`,
    },
    flightLists: {
        getByAirportFilter: (airportCode) => `/airportsFilter/${airportCode}`,
        getByAirport: (airportCode, arrDepIndicator, startPage, endPage) => {
            const pageQuery = formatPageRangeQuery(startPage, endPage);
            return `/airports/${airportCode}/flights/${arrDepIndicator}${pageQuery}`;
        },
        getFlight: (idFlight) => `/airports/flight/${idFlight}`,
    },
    flightDetails: {
        getByFlight: (flightId, arrDepIndicator) => `/flights/${arrDepIndicator}/${flightId}`,
        getByLinkedFlight: (flightId) => `flights/linkedflight/${flightId}`,
    },
    options: {
        LinkFlightOptions: (flightId) => `/options/linkflights/${flightId}`,
    },
    flightFieldsOptions: (airportCode, selectedField, page, searchText) => {
        const queryString = selectedField && (page || searchText) ? `?${formatQueryString({ page, searchText })}` : '';
        return `/flightFields/options/${airportCode}/${selectedField || ''}${queryString}`;
    },
    singleFlightFieldsOption: (selectedField, optionId) => `/flightFields/option/${selectedField}/${optionId}`,
    towing: {
        getListByAirport: (airportCode, startPage, endPage) => `/airports/${airportCode}/towing${formatPageRangeQuery(startPage, endPage)}`,
        getTowingDetails: (towingId) => `/towing/${towingId}`,
        createTowingMovement: `/towingMovement`,
        deleteTowingMovement: (movementId) => `/deleteTowingMovement/${movementId}`,
        editTowingMovement: (towingId) => `/towingMovement/${towingId}`,
        validate: '/towingMovement/validate',
    },
    Fids: {
        getDeviceFidsData: (deviceToken, page, isCompact = false) => {
            const pageQuery = page && page > 0 ? `?page=${page}` : '';
            let compactQuery = '';
            if (isCompact) {
                compactQuery = pageQuery ? `&isCompact=true` : '?isCompact=true';
            }
            return `/fids/device/${deviceToken}${pageQuery}${compactQuery}`;
        },
    },
    Reports: {
        getTemplatesList: (reportType) => `/reports/${reportType}/templates`,
        getTemplateDetails: (reportType, templateId) => `/reports/${reportType}/templates/${templateId}`,
        getSchedulesTemplateDetails: (reportType, templateId) => `/reports/${reportType}/templates/${templateId}/schedules`,
        getGeneratedReportsList: `/reports`,
        getGeneratedReportTemporaryUrl: (reportId) => `/reports/download/${reportId}`,
        getScheduledReportsList: `/reports/schedules`,
        getOptions: (reportType) => reportType ? `/reports/${reportType}/options` : `/reports/options`,
        getPaginatedOptions: (reportType, fieldName) => `/reports/${reportType}/options/${fieldName}`,
        generateReport: (reportType) => `/reports/${reportType}`,
        specificTemplate: (reportType, templateId) => `/reports/${reportType}/templates/${templateId}`,
        specificSchedule: (scheduleId) => `/reports/schedules/${scheduleId}`,
        saveTemplate: (reportType) => `/reports/${reportType}/templates`,
        emailsSchedule: () => `/emails`,
        getSpecificEmailOptions: (emailId) => `/emails/${emailId}`,
    },
    Tower: {
        getFlightsLists: (airportCode) => `/tower/${airportCode}`,
    },
    Dashboard: {
        getDashboardData: (airportCode, page) => {
            const pageQuery = page && page > 0 ? `?page=${page}` : '';
            return `/dashboard/${airportCode}${pageQuery}`;
        },
        dashboardSettings: () => `/dashboard/settings`,
    },
    Chat: {
        sendMessage: '/chat/message',
        getFlightChatThreads: (flightId) => `/chat/threads/flight/${flightId}`,
        getThreadInfo: (threadId) => `/chat/thread/${threadId}`,
        getThreadNotifications: (threadId) => `/chat/thread/${threadId}/notifications`,
        getInitialNotifications: '/chat/notifications',
        markThreadAsRead: (threadId) => `/chat/notifications/${threadId}`,
        changeStatusAmbulift: (threadId) => `/chat/thread/${threadId}/ambulift_status`,
        changeRequestStatus: (threadId) => `chat/thread/${threadId}/request/status`,
    },
    Gantt: {
        getSegmentsData: (airportCode, stardDate, endDate) => `/gantt/${airportCode}/${stardDate}/${endDate}`,
        getConstructionOptions: (airportCode) => `/gantt/${airportCode}/constructions/options`,
        getGanttFilterOptions: (airportCode) => `/gantt/${airportCode}/options`,
        getConstruction: (airportCode, id) => `/gantt/${airportCode}/constructions/${id}`,
        createNewConstruction: (airportCode) => `/gantt/${airportCode}/constructions`,
        updateConstruction: (airportCode, constructionId) => `/gantt/${airportCode}/constructions/${constructionId}`,
        validateConstruction: (airportCode) => `/gantt/${airportCode}/constructions/validate`,
        deleteConstruction: (airportCode, constructionId) => `/gantt/${airportCode}/constructions/${constructionId}`,
    },
};
exports.default = ApiUrls;
