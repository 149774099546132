"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function GetConfig() {
    let winLocationProtocol = 'http';
    if (typeof window !== 'undefined') {
        winLocationProtocol = window.location.protocol;
    }
    const AZURE_COMMUNICATION_SERVICE_ENDPOINT = `https://${process.env.AZURE_COMMUNICATION_RESOURCE}.communication.azure.com/`;
    const config = {
        staticPath: `${winLocationProtocol}//${process.env.BASE_API_URL}/static`,
        API_URL: `${winLocationProtocol}//${process.env.BASE_API_URL}/api/v1/`,
        BASE_API_URL: `${winLocationProtocol}//${process.env.BASE_API_URL}`,
        ADMIN_URL: `${winLocationProtocol}//${process.env.BASE_API_URL}/admin/`,
        BACKOFFICE_URL: `${winLocationProtocol}//${process.env.BASE_API_URL}/backoffice/`,
        AD_AUTH_ENABLED: `${process.env.AD_AUTH_ENABLED}` === 'true',
        AZURE_COMMUNICATION_ENABLED: `${process.env.AZURE_COMMUNICATION_ENABLED}` === 'true',
        AZURE_COMMUNICATION_NOTIFICATION_CENTER_ENABLED: `${process.env.AZURE_COMMUNICATION_NOTIFICATION_CENTER_ENABLED}` === 'true',
        AZURE_COMMUNICATION_SERVICE_ENDPOINT,
        AZURE_COMMUNICATION_SERVICES_CONNECTION_STRING: `endpoint=${AZURE_COMMUNICATION_SERVICE_ENDPOINT};accesskey=${process.env.AZURE_COMMUNICATION_KEY}`,
        AD_CLIENT_ID: `${process.env.AD_CLIENT_ID}`,
        AD_AUTHORITY: `${process.env.AD_AUTHORITY}`,
        AD_REDIRECT_URI: `${process.env.AD_REDIRECT_URI}`,
        GANTT_ENABLED: `${process.env.GANTT_ENABLED}` === 'true',
    };
    return config;
}
const config = GetConfig();
exports.default = config;
