"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAmbuliftChatNotifications = exports.useFlightChatNotifications = exports.useFilteredChatNotifications = void 0;
const react_1 = require("react");
const chat_context_types_1 = require("@/components/chat/chat-context-types");
const __1 = require("..");
function useFilteredChatNotifications(filterFunction) {
    const { notifications } = (0, __1.useChatNotifications)();
    const filteredNotifications = (0, react_1.useMemo)(() => Object.fromEntries(Object.entries(notifications).filter(filterFunction)), [notifications, filterFunction]);
    return filteredNotifications;
}
exports.useFilteredChatNotifications = useFilteredChatNotifications;
function useFlightChatNotifications(flightId) {
    const filterFunction = (0, react_1.useCallback)(([, data]) => data[chat_context_types_1.ThreadNotificationFieldNames.flightId] === flightId, [
        flightId,
    ]);
    return useFilteredChatNotifications(filterFunction);
}
exports.useFlightChatNotifications = useFlightChatNotifications;
function useAmbuliftChatNotifications(ambuliftRequestId) {
    const filterFunction = (0, react_1.useCallback)(([, data]) => data[chat_context_types_1.ThreadNotificationFieldNames.ambuliftRequestId] === ambuliftRequestId, [ambuliftRequestId]);
    return useFilteredChatNotifications(filterFunction);
}
exports.useAmbuliftChatNotifications = useAmbuliftChatNotifications;
