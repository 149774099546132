"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkDivertedFlightStatus = exports.checkCancelledFlightStatus = exports.checkFlightStatus = void 0;
const flight_1 = require("@/constants/flight");
const checkFlightStatus = (item, status) => {
    let currentStatus = item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.currentStatus];
    if (typeof currentStatus === 'object')
        currentStatus = currentStatus === null || currentStatus === void 0 ? void 0 : currentStatus.code;
    return currentStatus === status;
};
exports.checkFlightStatus = checkFlightStatus;
const checkCancelledFlightStatus = (item) => (0, exports.checkFlightStatus)(item, flight_1.FlightStatus.cancelled);
exports.checkCancelledFlightStatus = checkCancelledFlightStatus;
const checkDivertedFlightStatus = (item) => (0, exports.checkFlightStatus)(item, flight_1.FlightStatus.diverted);
exports.checkDivertedFlightStatus = checkDivertedFlightStatus;
