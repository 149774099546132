"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anyPropertyContainsText = exports.getPropertyFromObject = void 0;
const getPropertyFromObject = (obj, prop) => {
    if (typeof obj === undefined)
        return undefined;
    const _index = prop.indexOf('.');
    if (_index > -1) {
        const strBeforeDot = prop.substring(0, _index);
        const strAfterDot = prop.substring(_index + 1);
        return (0, exports.getPropertyFromObject)(obj[strBeforeDot], strAfterDot);
    }
    return obj[prop];
};
exports.getPropertyFromObject = getPropertyFromObject;
const anyPropertyContainsText = (item, textValue) => {
    /* eslint-disable no-restricted-syntax */
    if (item === null || item === undefined)
        return false;
    if (typeof item === 'object') {
        for (const key of Object.keys(item)) {
            if ((0, exports.anyPropertyContainsText)(item[key], textValue) === true) {
                return true;
            }
        }
        return false;
    }
    return String(item)
        .toLowerCase()
        .includes(textValue.toLowerCase());
    /* eslint-disable no-restricted-syntax */
};
exports.anyPropertyContainsText = anyPropertyContainsText;
