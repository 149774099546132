"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useSearchText = (timeout, handleSearchTextChange, handleSearchTextReset) => {
    const [searchText, _setSearchText] = (0, react_1.useState)('');
    const timeoutRef = (0, react_1.useRef)();
    const setSearchText = (0, react_1.useCallback)((text) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            handleSearchTextChange === null || handleSearchTextChange === void 0 ? void 0 : handleSearchTextChange(text);
        }, timeout);
        _setSearchText(text);
    }, [handleSearchTextChange]);
    const resetSearchText = () => {
        if (!searchText)
            return;
        _setSearchText('');
        handleSearchTextReset === null || handleSearchTextReset === void 0 ? void 0 : handleSearchTextReset();
    };
    return { searchText, setSearchText, resetSearchText };
};
exports.default = useSearchText;
