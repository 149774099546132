"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@emotion/core");
const Styles = {
    icon: (size) => (0, core_1.css) `
    ${size === 'regular' &&
        `width: 52px;
      height: 52px;`}
    ${size === 'small' &&
        `width: 24px;
      height: 24px;`}
  `,
};
exports.default = Styles;
