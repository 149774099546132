"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleLinkedFlightsData = exports.handleFlightFetchedData = exports.handleDepartureFlightsData = exports.handleArrivalFlightsData = exports.insertAfterPair = exports.getPairedListWithUpdatedPair = exports.pairedFlightListFromItems = exports.getListWithUpdatedFlightFromListData = exports.getListWithUpdatedFlight = exports.formatLinkedFlightList = exports.formatFlightList = exports.formatFlightListItem = void 0;
/* eslint-disable indent */
const flight_1 = require("@/constants/flight");
const types_1 = require("@/constants/types");
const date_1 = require("@/services/date");
const arrivalFlightTimeFields = [
    'currentEstimatedTime',
    'actualInBlockTime',
    'actualTouchdownTime',
];
const departureFlightTimeFields = [
    'currentEstimatedTime',
    'actualOffBlockTime',
    'actualTakeOffTime',
];
const buildTimeSectionFromFieldName = (flight) => (entries, field) => [
    ...entries,
    [
        [flight_1.FlightFieldNames[field]],
        (flight === null || flight === void 0 ? void 0 : flight[flight_1.FlightFieldNames[field]]) ? (0, date_1.formatTime)(flight[flight_1.FlightFieldNames[field]]) : '',
    ],
    [
        [flight_1.FlightDatetimeFields[field]],
        (flight === null || flight === void 0 ? void 0 : flight[flight_1.FlightFieldNames[field]]) ? new Date(flight[flight_1.FlightFieldNames[field]]) : null,
    ],
];
const formatFlightListItemTimeFields = (flight, arrDepIndicator) => {
    const fields = arrDepIndicator === types_1.FlightRouteType.departure
        ? departureFlightTimeFields
        : arrivalFlightTimeFields;
    return Object.fromEntries(fields.reduce(buildTimeSectionFromFieldName(flight), []));
};
const formatFlightListItemScheduleDateTime = (flight, arrDepIndicator) => {
    const flightScheduledTimeFieldName = arrDepIndicator === types_1.FlightRouteType.departure
        ? flight_1.FlightFieldNames.scheduledDepartureDatetime
        : flight_1.FlightFieldNames.scheduledArrivalDatetime;
    const scheduleDateTimeObj = (flight === null || flight === void 0 ? void 0 : flight[flightScheduledTimeFieldName])
        ? new Date(flight === null || flight === void 0 ? void 0 : flight[flightScheduledTimeFieldName])
        : null;
    return scheduleDateTimeObj
        ? (0, date_1.formatDateAndTime)(scheduleDateTimeObj)
        : { date: undefined, time: undefined };
};
const formatFlightListItem = (flight, arrDepIndicator) => {
    const timeFields = formatFlightListItemTimeFields(flight, arrDepIndicator);
    const scheduleDateTime = formatFlightListItemScheduleDateTime(flight, arrDepIndicator);
    return {
        ...flight,
        ...timeFields,
        scheduleDate: scheduleDateTime,
    };
};
exports.formatFlightListItem = formatFlightListItem;
const formatFlightList = (list, arrDepIndicator) => {
    const flightList = {
        count: list.count,
        items: [],
    };
    flightList.items = list.items.map(item => (0, exports.formatFlightListItem)(item, arrDepIndicator));
    return flightList;
};
exports.formatFlightList = formatFlightList;
const formatLinkedFlightList = (list) => ({
    count: list.count,
    items: list.items.map(flightPair => ({
        arrivalFlight: flightPair.arrival_flight &&
            (0, exports.formatFlightListItem)(flightPair.arrival_flight, types_1.FlightRouteType.arrival),
        departureFlight: flightPair.departure_flight &&
            (0, exports.formatFlightListItem)(flightPair.departure_flight, types_1.FlightRouteType.departure),
    })),
});
exports.formatLinkedFlightList = formatLinkedFlightList;
const getListWithUpdatedFlight = (list, updatedFlight, isArrival) => {
    var _a;
    const routeType = isArrival ? types_1.FlightRouteType.arrival : types_1.FlightRouteType.departure;
    return ((_a = list === null || list === void 0 ? void 0 : list.map((item) => updatedFlight.id === item.id ? (0, exports.formatFlightListItem)(updatedFlight, routeType) : item)) !== null && _a !== void 0 ? _a : []);
};
exports.getListWithUpdatedFlight = getListWithUpdatedFlight;
const getListWithUpdatedFlightFromListData = (list, updatedFlight, isArrival) => (0, exports.getListWithUpdatedFlight)(list === null || list === void 0 ? void 0 : list.items, updatedFlight, isArrival);
exports.getListWithUpdatedFlightFromListData = getListWithUpdatedFlightFromListData;
const getPairIds = (pair) => { var _a, _b; return [(_a = pair === null || pair === void 0 ? void 0 : pair.arrivalFlight) === null || _a === void 0 ? void 0 : _a.id, (_b = pair === null || pair === void 0 ? void 0 : pair.departureFlight) === null || _b === void 0 ? void 0 : _b.id]; };
const pairedFlightListFromItems = (items) => ({
    count: items.length,
    items,
});
exports.pairedFlightListFromItems = pairedFlightListFromItems;
const getPairedListWithUpdatedPair = (list, updatedPair) => {
    var _a;
    const [updatedArrivalId, updatedDepartureId] = getPairIds(updatedPair);
    return ((_a = list === null || list === void 0 ? void 0 : list.items.reduce((acc, currentPair) => {
        const [oldArrivalId, oldDepartureId] = getPairIds(currentPair);
        const { newList, pairAlreadyFound } = acc;
        const oldPairHasFlightInCommon = (updatedArrivalId && oldArrivalId === updatedArrivalId) ||
            (updatedDepartureId && oldDepartureId === updatedDepartureId);
        const shouldSkip = pairAlreadyFound && oldPairHasFlightInCommon;
        const pairToInsert = oldPairHasFlightInCommon ? updatedPair : currentPair;
        return {
            newList: shouldSkip ? newList : [...newList, pairToInsert],
            pairAlreadyFound: pairAlreadyFound || oldPairHasFlightInCommon,
        };
    }, { newList: [], pairAlreadyFound: false }).newList) !== null && _a !== void 0 ? _a : null);
};
exports.getPairedListWithUpdatedPair = getPairedListWithUpdatedPair;
const insertAfterPair = (list, testPair, pairToInsert) => {
    const firstMatchIndex = list.items.findIndex(testPair);
    const updatedList = [
        ...list.items.slice(0, firstMatchIndex + 1),
        pairToInsert,
        ...list.items.slice(firstMatchIndex + 1),
    ];
    return updatedList;
};
exports.insertAfterPair = insertAfterPair;
const handleArrivalFlightsData = (data, setArrivalLists) => {
    /* eslint-disable camelcase */
    const arrivalFlights = {
        count: data === null || data === void 0 ? void 0 : data.arrival_flights.length,
        items: data === null || data === void 0 ? void 0 : data.arrival_flights,
    };
    setArrivalLists((0, exports.formatFlightList)(arrivalFlights, types_1.FlightRouteType.arrival));
};
exports.handleArrivalFlightsData = handleArrivalFlightsData;
const handleDepartureFlightsData = (data, setDepartureLists) => {
    /* eslint-disable camelcase */
    const departureFlights = {
        count: data === null || data === void 0 ? void 0 : data.departure_flights.length,
        items: data === null || data === void 0 ? void 0 : data.departure_flights,
    };
    setDepartureLists((0, exports.formatFlightList)(departureFlights, types_1.FlightRouteType.departure));
};
exports.handleDepartureFlightsData = handleDepartureFlightsData;
const handleFlightFetchedData = (data, setFlightLists) => {
    /* eslint-disable camelcase */
    const arrivalFlights = {
        count: data === null || data === void 0 ? void 0 : data.arrival_flights.length,
        items: data === null || data === void 0 ? void 0 : data.arrival_flights,
    };
    const departureFlights = {
        count: data.departure_flights.length,
        items: data.departure_flights,
    };
    setFlightLists({
        arrivalFlights: (0, exports.formatFlightList)(arrivalFlights, types_1.FlightRouteType.arrival),
        departureFlights: (0, exports.formatFlightList)(departureFlights, types_1.FlightRouteType.departure),
    });
    /* eslint-enable camelcase */
};
exports.handleFlightFetchedData = handleFlightFetchedData;
const handleLinkedFlightsData = (data, setFlightLists) => {
    setFlightLists((0, exports.formatLinkedFlightList)(data));
};
exports.handleLinkedFlightsData = handleLinkedFlightsData;
