"use strict";
/* eslint-disable camelcase */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchedulePreset = exports.ScheduleFilterKey = exports.ActivitiesFilterKey = exports.ReportPeriod = exports.GeneralReportOptions = exports.ReportTypes = void 0;
var ReportTypes;
(function (ReportTypes) {
    ReportTypes["rima"] = "rima";
    ReportTypes["punctuality"] = "punctuality";
    ReportTypes["turnaround"] = "turnaround";
    ReportTypes["fulfilled"] = "fulfilled";
    ReportTypes["future"] = "future";
    ReportTypes["cancelled"] = "cancelled";
    ReportTypes["pnae"] = "pnae";
    ReportTypes["pdo"] = "pdo";
})(ReportTypes = exports.ReportTypes || (exports.ReportTypes = {}));
var GeneralReportOptions;
(function (GeneralReportOptions) {
    GeneralReportOptions["activities"] = "activities";
    GeneralReportOptions["schedules"] = "schedules";
})(GeneralReportOptions = exports.GeneralReportOptions || (exports.GeneralReportOptions = {}));
var ReportPeriod;
(function (ReportPeriod) {
    ReportPeriod["CURRENT_DAY"] = "Current Day";
    ReportPeriod["LAST_DAY"] = "Last Day";
    ReportPeriod["CURRENT_MONTH"] = "Current Month";
    ReportPeriod["LAST_MONTH"] = "Last Month";
    ReportPeriod["CURRENT_WEEK"] = "Current Week";
    ReportPeriod["LAST_WEEK"] = "Last Week";
    ReportPeriod["PAST_7_DAYS"] = "Past 7 Days";
    ReportPeriod["PAST_30_DAYS"] = "Past 30 Days";
    ReportPeriod["CUSTOM"] = "Custom";
})(ReportPeriod = exports.ReportPeriod || (exports.ReportPeriod = {}));
var ActivitiesFilterKey;
(function (ActivitiesFilterKey) {
    ActivitiesFilterKey["REPORT_TYPE"] = "report_type";
    ActivitiesFilterKey["FROM"] = "from";
    ActivitiesFilterKey["TO"] = "to";
    ActivitiesFilterKey["STATUS"] = "status";
})(ActivitiesFilterKey = exports.ActivitiesFilterKey || (exports.ActivitiesFilterKey = {}));
var ScheduleFilterKey;
(function (ScheduleFilterKey) {
    ScheduleFilterKey["REPORT_TYPE"] = "report_type";
    ScheduleFilterKey["PRESET"] = "preset";
    ScheduleFilterKey["WEEKDAY"] = "weekday";
    ScheduleFilterKey["DAY"] = "day";
    ScheduleFilterKey["EMAILS"] = "emails";
})(ScheduleFilterKey = exports.ScheduleFilterKey || (exports.ScheduleFilterKey = {}));
var SchedulePreset;
(function (SchedulePreset) {
    SchedulePreset["DAILY"] = "Daily";
    SchedulePreset["WEEKLY"] = "Weekly";
    SchedulePreset["MONTHLY"] = "Monthly";
})(SchedulePreset = exports.SchedulePreset || (exports.SchedulePreset = {}));
