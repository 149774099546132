"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserSectionDefaultPage = exports.getUserSectionFromRoute = exports.getCurrentPageRoutesKey = void 0;
const routes_1 = __importStar(require("@/constants/routes"));
const getCurrentPageRoutesKey = (pathname) => Object.keys(routes_1.RoutesRegex).find(route => new RegExp(routes_1.RoutesRegex[route]).test(pathname));
exports.getCurrentPageRoutesKey = getCurrentPageRoutesKey;
const getUserSectionFromRoute = (routeKey) => Object.keys(routes_1.UserSectionsRoutes).find(userSection => {
    const routes = routes_1.UserSectionsRoutes[userSection];
    const currentRoute = routes_1.default[routeKey];
    return routes.includes(currentRoute);
});
exports.getUserSectionFromRoute = getUserSectionFromRoute;
const getUserSectionDefaultPage = (pathname, airport) => {
    if (!airport)
        return routes_1.default.Root;
    const routesKey = (0, exports.getCurrentPageRoutesKey)(pathname);
    const userSection = routesKey ? (0, exports.getUserSectionFromRoute)(routesKey) : undefined;
    if (!userSection || !(userSection in routes_1.DefaultUserSectionRoute))
        return routes_1.default.Root;
    const getDefaultPage = routes_1.DefaultUserSectionRoute[userSection];
    return getDefaultPage(airport);
};
exports.getUserSectionDefaultPage = getUserSectionDefaultPage;
