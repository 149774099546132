"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@chakra-ui/react");
const react_2 = require("react");
const flight_1 = require("@/constants/flight");
const userPermission_1 = require("@/constants/userPermission");
const flightDetails_1 = require("@/services/flightDetails");
const useAircraftModal = (user) => {
    var _a, _b;
    const createAircraftDisclosure = (0, react_1.useDisclosure)();
    const editAircraftDisclosure = (0, react_1.useDisclosure)();
    const [aircraftSearchValue, setAircraftSearchedValue] = (0, react_2.useState)('');
    const canCreateAircraft = (_a = user === null || user === void 0 ? void 0 : user.permissions) === null || _a === void 0 ? void 0 : _a.userHasPermission(userPermission_1.PermissionModel.AIRCRAFT, userPermission_1.PermissionAction.AIRCRAFT_ADD);
    const canEditAircraft = (_b = user === null || user === void 0 ? void 0 : user.permissions) === null || _b === void 0 ? void 0 : _b.userHasPermission(userPermission_1.PermissionModel.AIRCRAFT, userPermission_1.PermissionAction.AIRCRAFT_CHANGE);
    const handleAircraftChange = (0, react_2.useCallback)((aircraft, setFieldValue) => {
        var _a;
        const aircraftType = aircraft === null || aircraft === void 0 ? void 0 : aircraft[flight_1.FlightFieldNames.aircraftType];
        const aircraftTypeId = typeof aircraftType === 'object' ? aircraftType.id : aircraftType;
        setFieldValue(flight_1.FlightFieldNames.aircraft, (_a = aircraft === null || aircraft === void 0 ? void 0 : aircraft.id) !== null && _a !== void 0 ? _a : null);
        setFieldValue(flight_1.FlightFieldNames.aircraftType, aircraftTypeId !== null && aircraftTypeId !== void 0 ? aircraftTypeId : null);
    }, [user]);
    const handleOnCreateAircraftOption = (0, react_2.useCallback)((value) => {
        if (canCreateAircraft) {
            setAircraftSearchedValue(value);
            createAircraftDisclosure.onOpen();
        }
    }, [user]);
    const [editableAircraft, setEditableAircraft] = (0, react_2.useState)();
    const [triggerResetAircraftPagination, setTriggerResetAircraftPagination] = (0, react_2.useState)(false);
    const handleOnOpenAircraftEditModal = (0, react_2.useCallback)((values) => (0, flightDetails_1.loadSingleFlightFieldOption)(flight_1.FieldOptions.AIRCRAFT, values === null || values === void 0 ? void 0 : values[flight_1.FlightFieldNames.aircraft]).then(response => {
        editAircraftDisclosure.onOpen();
        setEditableAircraft(response.data);
    }), [user]);
    const handleOnCloseAircraftEditModal = (0, react_2.useCallback)(() => {
        setTriggerResetAircraftPagination(true);
        editAircraftDisclosure.onClose();
        setTriggerResetAircraftPagination(false);
    }, [user]);
    return {
        aircraftSearchValue,
        canCreateAircraft,
        canEditAircraft,
        createAircraftDisclosure,
        editAircraftDisclosure,
        editableAircraft,
        triggerResetAircraftPagination,
        handleOnCreateAircraftOption,
        handleOnOpenAircraftEditModal,
        handleOnCloseAircraftEditModal,
        handleAircraftChange,
    };
};
exports.default = useAircraftModal;
