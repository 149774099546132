"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultUserSectionRoute = exports.UserSectionsRoutes = exports.RoutesRegex = void 0;
const types_1 = require("@/constants/types");
const types_d_1 = require("@/screens/reports-screen/types.d");
const airportRegex = '([A-Z]{4})';
const airportField = `/:airportCode${airportRegex}`;
const numericIdRegex = '([\\d]+)';
const flightIdField = `:flightId${numericIdRegex}`;
const towingIdField = `:towingId${numericIdRegex}`;
const arrivalDepRegex = '(arrival|departure)';
const arrivalDeparture = `:arrDepIndicator${arrivalDepRegex}`;
const reportsTabRegex = '(activities|schedules|rima|punctuality|turnaround|cancelled|fulfilled|future|pnae|pdo)';
const reportsTab = `:reportsTab${reportsTabRegex}`;
const reportTypeRegex = `(rima|punctuality|turnaround|cancelled|fulfilled|future|pnae|pdo)`;
const reportType = `:reportType${reportTypeRegex}`;
const templateId = `:templateId${numericIdRegex}`;
const Routes = {
    Login: '/login',
    Root: '/',
    BaseAirportRoute: `${airportField}`,
    FlightControl: `${airportField}/flight-control`,
    FlightEdit: `${airportField}/flight/${arrivalDeparture}/${flightIdField}`,
    FlightCreate: `${airportField}/flight/create`,
    getFlightControlRoute: (airportCode) => `/${airportCode}/flight-control`,
    getFlightCreateRoute: (airportCode) => `/${airportCode}/flight/create`,
    getFlightEditRoute: (airportCode, isArrival, id) => {
        if (airportCode === null)
            return `/airportSelect`;
        const arrDepIndicator = isArrival ? types_1.FlightRouteType.arrival : types_1.FlightRouteType.departure;
        return `/${airportCode}/flight/${arrDepIndicator}/${id}`;
    },
    TowingDetails: `${airportField}/towing/${towingIdField}`,
    getTowingDetailsRoute: (airportCode, id) => `/${airportCode}/towing/${id}`,
    Reports: `${airportField}/reports/${reportsTab}`,
    ReportTemplate: `${airportField}/reports/${reportType}/templates/${templateId}`,
    getReportsRoute: (airportCode, tabName) => `/${airportCode}/reports/${tabName}`,
    getReportTemplateRoute: (airportCode, _reportType, _templateId) => `/${airportCode}/reports/${_reportType}/templates/${_templateId}`,
    Fids: '/fids/:deviceToken',
    Tower: `${airportField}/tower`,
    getTowerRoute: (airportCode) => `/${airportCode}/tower`,
    Dashboard: `${airportField}/dashboard`,
    getDashboardRoute: (airportCode) => `/${airportCode}/dashboard`,
};
exports.RoutesRegex = {
    Login: Routes.Login,
    FlightEdit: `/${airportRegex}/flight/${arrivalDepRegex}/${numericIdRegex}`,
    FlightControl: `/${airportRegex}/flight-control`,
    FlightCreate: `/${airportRegex}/flight/create`,
    TowingDetails: `${airportRegex}/towing/${numericIdRegex}`,
    Reports: `${airportRegex}/reports/${reportsTabRegex}`,
    Tower: `/${airportRegex}/tower`,
    Fids: /\/fids\/.+/,
    Dashboard: `/${airportRegex}/dashboard`,
};
exports.UserSectionsRoutes = {
    [types_1.SidebarSections.APOC]: [
        Routes.FlightEdit,
        Routes.FlightControl,
        Routes.FlightCreate,
        Routes.TowingDetails,
    ],
    [types_1.SidebarSections.REPORTS]: [Routes.Reports],
    [types_1.SidebarSections.TOWER]: [Routes.Tower],
    [types_1.SidebarSections.DASHBOARD]: [Routes.Dashboard],
};
exports.DefaultUserSectionRoute = {
    [types_1.SidebarSections.APOC]: (currentAirport) => Routes.getFlightControlRoute(currentAirport),
    [types_1.SidebarSections.REPORTS]: (currentAirport) => Routes.getReportsRoute(currentAirport, types_d_1.GeneralReportOptions.activities),
    [types_1.SidebarSections.TOWER]: (currentAirport) => Routes.getTowerRoute(currentAirport),
    [types_1.SidebarSections.DASHBOARD]: (currentAirport) => Routes.getDashboardRoute(currentAirport),
};
exports.default = Routes;
