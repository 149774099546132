"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlightSidebarKeys = exports.CronTimes = exports.SidebarSections = exports.TabCategories = exports.AirportTabType = exports.FlightRouteType = void 0;
var FlightRouteType;
(function (FlightRouteType) {
    FlightRouteType["arrival"] = "arrival";
    FlightRouteType["departure"] = "departure";
})(FlightRouteType = exports.FlightRouteType || (exports.FlightRouteType = {}));
var AirportTabType;
(function (AirportTabType) {
    AirportTabType["arrival"] = "arrival";
    AirportTabType["departure"] = "departure";
    AirportTabType["both"] = "both";
    AirportTabType["linked"] = "linked";
    AirportTabType["towing"] = "towing";
    AirportTabType["gantt"] = "gantt";
})(AirportTabType = exports.AirportTabType || (exports.AirportTabType = {}));
var TabCategories;
(function (TabCategories) {
    TabCategories["arrival"] = "flight";
    TabCategories["departure"] = "flight";
    TabCategories["both"] = "flight";
    TabCategories["linked"] = "flight";
    TabCategories["towing"] = "towing";
    TabCategories["gantt"] = "gantt";
})(TabCategories = exports.TabCategories || (exports.TabCategories = {}));
var SidebarSections;
(function (SidebarSections) {
    SidebarSections["APOC"] = "apoc";
    SidebarSections["REPORTS"] = "reports";
    SidebarSections["TOWER"] = "tower";
    SidebarSections["DASHBOARD"] = "dashboard";
})(SidebarSections = exports.SidebarSections || (exports.SidebarSections = {}));
var CronTimes;
(function (CronTimes) {
    CronTimes["MINUTE"] = "minute";
    CronTimes["HOUR"] = "hour";
})(CronTimes = exports.CronTimes || (exports.CronTimes = {}));
var FlightSidebarKeys;
(function (FlightSidebarKeys) {
    FlightSidebarKeys["FLIGHT"] = "FLIGHT";
    FlightSidebarKeys["ROUTE"] = "ROUTE";
    FlightSidebarKeys["SERVICE"] = "SERVICE";
    FlightSidebarKeys["SCHEDULE"] = "SCHEDULE";
    FlightSidebarKeys["RESOURCE"] = "RESOURCE";
    FlightSidebarKeys["PASSENGERS"] = "PASSENGERS";
    FlightSidebarKeys["SHARED"] = "SHARED";
    FlightSidebarKeys["OBSERVATIONS"] = "OBSERVATIONS";
    FlightSidebarKeys["LINKED_FLIGHT"] = "LINKED_FLIGHT";
})(FlightSidebarKeys = exports.FlightSidebarKeys || (exports.FlightSidebarKeys = {}));
