"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConstructionFields = void 0;
var ConstructionFields;
(function (ConstructionFields) {
    ConstructionFields["id"] = "id";
    ConstructionFields["constructionType"] = "construction_type";
    ConstructionFields["airport"] = "airport";
    ConstructionFields["stands"] = "stands";
    ConstructionFields["reclaimBelts"] = "reclaim_belts";
    ConstructionFields["gates"] = "gates";
    ConstructionFields["beginningDatetime"] = "beginning_datetime";
    ConstructionFields["endingDatetime"] = "ending_datetime";
})(ConstructionFields = exports.ConstructionFields || (exports.ConstructionFields = {}));
