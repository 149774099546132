"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendBothListsNewPageData = exports.appendSingleListNewPageData = void 0;
const appendSingleListNewPageData = ({ data, newData, }) => ({
    fullData: {
        count: data.count + newData.count,
        items: data.items.concat(newData.items),
    },
    hasNext: newData.count !== 0,
});
exports.appendSingleListNewPageData = appendSingleListNewPageData;
const appendBothListsNewPageData = ({ data, newData, }) => {
    const arrivalFlights = (0, exports.appendSingleListNewPageData)({
        data: data.arrivalFlights,
        newData: newData.arrivalFlights,
    });
    const departureFlights = (0, exports.appendSingleListNewPageData)({
        data: data.departureFlights,
        newData: newData.departureFlights,
    });
    return {
        fullData: {
            arrivalFlights: { ...arrivalFlights.fullData },
            departureFlights: { ...departureFlights.fullData },
        },
        hasNext: arrivalFlights.hasNext || departureFlights.hasNext,
    };
};
exports.appendBothListsNewPageData = appendBothListsNewPageData;
