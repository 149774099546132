"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBlocked = exports.getDisabledFromPermission = exports.userHasFlightFieldPermission = void 0;
const flight_1 = require("@/constants/flight");
const userPermission_1 = require("@/constants/userPermission");
const userHasFlightFieldPermission = (user, fieldName) => { var _a, _b, _c; return (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.userPermissions) === null || _a === void 0 ? void 0 : _a[userPermission_1.PermissionModel.FLIGHT_FIELDS]) === null || _b === void 0 ? void 0 : _b[fieldName]) !== null && _c !== void 0 ? _c : false; };
exports.userHasFlightFieldPermission = userHasFlightFieldPermission;
const getDisabledFromPermission = (user, fieldName) => (0, exports.userHasFlightFieldPermission)(user, fieldName) ? undefined : true;
exports.getDisabledFromPermission = getDisabledFromPermission;
const useBlocked = (item, user) => {
    if (!user)
        return true;
    const [hasShortEditingPermission, hasMediumEditingPermission, hasLongEditingPermission] = [
        userPermission_1.PermissionAction.FLIGHT_CHANGE_SHORT_TIMESPAN,
        userPermission_1.PermissionAction.FLIGHT_CHANGE_MEDIUM_TIMESPAN,
        userPermission_1.PermissionAction.FLIGHT_CHANGE_LONG_TIMESPAN,
    ].map(permissionAction => user.permissions.userHasPermission(userPermission_1.PermissionModel.FLIGHT_EDIT_TIMESPAN, permissionAction));
    if (hasLongEditingPermission)
        return item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.isLongBlocked];
    if (hasMediumEditingPermission)
        return item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.isMediumBlocked];
    if (hasShortEditingPermission)
        return item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.isShortBlocked];
    return ((item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.isShortBlocked]) ||
        (item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.isMediumBlocked]) ||
        (item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.isLongBlocked]));
};
exports.useBlocked = useBlocked;
