"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useRefAndStatePair(initialValue) {
    const [value, setValue] = (0, react_1.useState)(initialValue);
    const valueRef = (0, react_1.useRef)(value);
    const _setValue = (newLoading) => {
        setValue(newLoading);
        valueRef.current = newLoading;
    };
    return [valueRef, value, _setValue];
}
exports.default = useRefAndStatePair;
