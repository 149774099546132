"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFocus = void 0;
const react_1 = require("react");
const useFocus = () => {
    const htmlElRef = (0, react_1.useRef)(null);
    const setFocus = () => { var _a; return (_a = htmlElRef === null || htmlElRef === void 0 ? void 0 : htmlElRef.current) === null || _a === void 0 ? void 0 : _a.focus(); };
    return [htmlElRef, setFocus];
};
exports.useFocus = useFocus;
exports.default = exports.useFocus;
