"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserPermissions = void 0;
const react_1 = require("react");
const types_1 = require("@/constants/types");
const userPermission_1 = require("@/constants/userPermission");
const useUserPermissions = ({ user }) => {
    const userHasPermission = (0, react_1.useCallback)((permissionModel, permissionAction) => { var _a, _b, _c; return (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.userPermissions) === null || _a === void 0 ? void 0 : _a[permissionModel]) === null || _b === void 0 ? void 0 : _b[permissionAction]) !== null && _c !== void 0 ? _c : false; }, [user]);
    const userHasFeature = (0, react_1.useCallback)((featurePermission) => userHasPermission(userPermission_1.PermissionModel.USER, featurePermission), [userHasPermission]);
    const userHasBackofficePermission = (0, react_1.useCallback)((permissionBackoffice) => { var _a, _b, _c; return (_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.userPermissions) === null || _a === void 0 ? void 0 : _a.backoffice) === null || _b === void 0 ? void 0 : _b[permissionBackoffice]) !== null && _c !== void 0 ? _c : false; }, [user]);
    const sectionPermissions = (0, react_1.useMemo)(() => ({
        [types_1.SidebarSections.APOC]: userHasFeature(userPermission_1.Feature.FLIGHT_CONTROL),
        [types_1.SidebarSections.DASHBOARD]: userHasFeature(userPermission_1.Feature.DASHBOARD),
        [types_1.SidebarSections.REPORTS]: userHasFeature(userPermission_1.Feature.REPORTS),
        [types_1.SidebarSections.TOWER]: userHasFeature(userPermission_1.Feature.TOWER),
    }), [userHasFeature]);
    const userCanAccessSection = (0, react_1.useCallback)((section) => section in sectionPermissions ? sectionPermissions[section] : false, [sectionPermissions]);
    const getUserPaths = (0, react_1.useCallback)(() => {
        let userPath = [...userPermission_1.defaultUserRoutes];
        Object.keys(userPermission_1.featureRoutes).forEach(feature => {
            if (userHasFeature(feature)) {
                const routes = userPermission_1.featureRoutes[feature];
                userPath = [...userPath, ...routes];
            }
        });
        return userPath;
    }, [userHasFeature]);
    const allowedUserPaths = (0, react_1.useMemo)(() => getUserPaths(), [getUserPaths]);
    return {
        allowedUserPaths,
        userCanAccessSection,
        userHasFeature,
        userHasPermission,
        userHasBackofficePermission,
    };
};
exports.useUserPermissions = useUserPermissions;
exports.default = exports.useUserPermissions;
