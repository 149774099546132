"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAbsoluteUrl = void 0;
const isAbsoluteUrl = (urlToTest) => {
    try {
        // eslint-disable-next-line no-new
        new URL(urlToTest);
        return true;
    }
    catch (ex) {
        // eslint-disable-next-line no-new
        new URL(urlToTest, document.baseURI); // THROW AN EXCEPTION IF THE URL IS TRULY MALFORMED IN SOME WAY
    }
    return false;
};
exports.isAbsoluteUrl = isAbsoluteUrl;
