"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmbuliftRequestStatus = exports.AmbuliftRequestFields = exports.PnaeFieldNames = void 0;
var PnaeFieldNames;
(function (PnaeFieldNames) {
    PnaeFieldNames["pnaeType"] = "pnae_type";
    PnaeFieldNames["hasAmbulift"] = "has_ambulift";
    PnaeFieldNames["necessaryTime"] = "scheduled_at";
    PnaeFieldNames["airportResource"] = "requested_airport_resource";
    PnaeFieldNames["comment"] = "comment";
    PnaeFieldNames["scheduledAt"] = "scheduled_at";
    PnaeFieldNames["fulfilledAt"] = "fulfilled_at";
    PnaeFieldNames["status"] = "status";
})(PnaeFieldNames = exports.PnaeFieldNames || (exports.PnaeFieldNames = {}));
var AmbuliftRequestFields;
(function (AmbuliftRequestFields) {
    AmbuliftRequestFields["pnaeType"] = "pnae_type";
    AmbuliftRequestFields["status"] = "status";
    AmbuliftRequestFields["scheduledAt"] = "scheduled_at";
    AmbuliftRequestFields["fulfilledAt"] = "fulfilled_at";
    AmbuliftRequestFields["id"] = "id";
})(AmbuliftRequestFields = exports.AmbuliftRequestFields || (exports.AmbuliftRequestFields = {}));
var AmbuliftRequestStatus;
(function (AmbuliftRequestStatus) {
    AmbuliftRequestStatus["WAITING"] = "waiting";
    AmbuliftRequestStatus["FINISHED"] = "finished";
    AmbuliftRequestStatus["CANCELLED"] = "cancelled";
    AmbuliftRequestStatus["APPROVED"] = "approved";
    AmbuliftRequestStatus["PENDING"] = "pending";
})(AmbuliftRequestStatus = exports.AmbuliftRequestStatus || (exports.AmbuliftRequestStatus = {}));
