"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useOrientation = () => {
    const [isPortrait, setIsPortrait] = (0, react_1.useState)(window.matchMedia('(orientation: portrait)').matches);
    (0, react_1.useEffect)(() => {
        const handleResize = () => {
            setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return isPortrait;
};
exports.default = useOrientation;
