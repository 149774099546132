"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriorityValue = exports.AlertLevels = exports.IssueType = void 0;
var IssueType;
(function (IssueType) {
    IssueType["withoutGate"] = "without_gate";
    IssueType["withoutReclaimBelt"] = "without_reclaim_belt";
    IssueType["withoutStand"] = "without_stand";
    IssueType["operationInfos"] = "operation_infos";
    IssueType["missingReasonCode"] = "missing_reason_code";
    IssueType["inconsistentSchedule"] = "inconsistent_schedule";
    IssueType["flightsWithoutLink"] = "flights_without_link";
    IssueType["emptyDateFields"] = "empty_date_fields";
    IssueType["pnaePendingStatus"] = "pnae_pending_status";
    IssueType["pnaeNotFulfilled"] = "pnae_request_not_fulfilled";
    IssueType["pnaeChatWithoutReply"] = "pnae_chat_without_reply";
    IssueType["taticPlaceholderValues"] = "tatic_placeholder_values";
    IssueType["placeholderServiceType"] = "placeholder_service_type";
    IssueType["placeholderFlightType"] = "placeholder_flight_type";
    IssueType["constructionConflict"] = "construction_conflict";
})(IssueType = exports.IssueType || (exports.IssueType = {}));
var AlertLevels;
(function (AlertLevels) {
    AlertLevels["high"] = "high";
    AlertLevels["medium"] = "medium";
    AlertLevels["low"] = "low";
})(AlertLevels = exports.AlertLevels || (exports.AlertLevels = {}));
var PriorityValue;
(function (PriorityValue) {
    PriorityValue[PriorityValue["high"] = 1] = "high";
    PriorityValue[PriorityValue["medium"] = 2] = "medium";
    PriorityValue[PriorityValue["low"] = 3] = "low";
})(PriorityValue = exports.PriorityValue || (exports.PriorityValue = {}));
