"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function usePagination() {
    const [page, setPage] = (0, react_1.useState)(1);
    const [pageCount, setPageCount] = (0, react_1.useState)(6);
    const [pageTotal, setPageTotal] = (0, react_1.useState)(0);
    return {
        page,
        setPage,
        pageCount,
        setPageCount,
        pageTotal,
        setPageTotal,
    };
}
exports.default = usePagination;
