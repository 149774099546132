"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const hooks_1 = require("@/hooks/");
const mergeOptionListsUniquely = (options, newOptions, getOptionIdentifier) => {
    const duplicatedOptionList = [...options, ...newOptions];
    const uniqueOptionList = duplicatedOptionList.reduce((map, obj) => map.set(getOptionIdentifier(obj), obj), new Map());
    return [...uniqueOptionList.values()];
};
const usePaginatedOptions = (args) => {
    const { loadPage, getOptionIdentifier, loadSpecificOption } = args;
    const [currentPage, setCurrentPage] = (0, react_1.useState)(0);
    const [hasMore, setHasMore] = (0, react_1.useState)(true);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [options, setOptions] = (0, react_1.useState)([]);
    const [loadMoreOptions, setLoadMoreOptions] = (0, react_1.useState)(true);
    const handleSearchTextChange = () => {
        if (loading)
            return;
        setCurrentPage(0);
        setHasMore(true);
        setLoadMoreOptions(true);
    };
    const handleSearchTextReset = () => {
        setCurrentPage(0);
        setHasMore(true);
    };
    const { searchText, setSearchText, resetSearchText } = (0, hooks_1.useSearchText)(500, handleSearchTextChange, handleSearchTextReset);
    const getMoreOptions = (0, react_1.useCallback)(() => {
        setLoading(true);
        const nextPage = currentPage + 1;
        loadPage(nextPage, searchText)
            .then(newOptions => {
            if (newOptions.length) {
                const mergedOptions = mergeOptionListsUniquely(options, newOptions, getOptionIdentifier);
                setOptions(mergedOptions);
                setCurrentPage(nextPage);
            }
            else {
                setHasMore(false);
            }
        })
            .finally(() => {
            setLoading(false);
        });
    }, [currentPage, options, searchText, loadPage]);
    (0, react_1.useEffect)(() => {
        if (loadMoreOptions && !loading && hasMore) {
            setLoadMoreOptions(false);
            getMoreOptions();
        }
    }, [loadMoreOptions, getMoreOptions]);
    const getSpecificOption = (0, react_1.useCallback)((valueId) => loadSpecificOption(valueId).then(optionData => setOptions(mergeOptionListsUniquely(options, [optionData], getOptionIdentifier))), [options, loadSpecificOption, getOptionIdentifier]);
    const resetPagination = (0, react_1.useCallback)(() => {
        resetSearchText();
        setCurrentPage(0);
        setOptions([]);
        setHasMore(true);
        setLoadMoreOptions(true);
    }, [setCurrentPage, setOptions, setHasMore]);
    const paginationState = (0, react_1.useMemo)(() => ({
        options,
        currentPage,
        hasMore,
        loading,
        getMoreOptions,
        getSpecificOption,
        resetPagination,
        setSearchText,
        resetSearchText,
    }), [
        options,
        currentPage,
        hasMore,
        loading,
        getMoreOptions,
        getSpecificOption,
        resetPagination,
        setSearchText,
        resetSearchText,
    ]);
    return paginationState;
};
exports.default = usePaginatedOptions;
