"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useFieldOptions(field, loader) {
    const [fieldOptions, setFieldOptions] = (0, react_1.useState)(null);
    const loading = (0, react_1.useRef)(false);
    const loadOptions = (0, react_1.useCallback)(() => {
        if (loading.current)
            return;
        loading.current = true;
        loader()
            .then(data => setFieldOptions(data))
            .finally(() => {
            loading.current = false;
        });
    }, [setFieldOptions, loader]);
    (0, react_1.useEffect)(() => {
        if (fieldOptions === null)
            loadOptions();
    }, [fieldOptions, loadOptions]);
    (0, react_1.useEffect)(() => {
        if (field)
            loadOptions();
    }, [field, loadOptions]);
    const reload = loadOptions;
    return [fieldOptions, reload, setFieldOptions];
}
exports.default = useFieldOptions;
