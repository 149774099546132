"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterLinkedList = exports.filterList = void 0;
const object_1 = require("@/services/object");
const filterList = (list, filterTextValue) => {
    var _a, _b;
    const filteredList = filterTextValue
        ? (_a = list === null || list === void 0 ? void 0 : list.items) === null || _a === void 0 ? void 0 : _a.filter(item => (0, object_1.anyPropertyContainsText)(item, filterTextValue))
        : list === null || list === void 0 ? void 0 : list.items;
    return {
        count: (_b = filteredList === null || filteredList === void 0 ? void 0 : filteredList.length) !== null && _b !== void 0 ? _b : 0,
        items: filteredList,
    };
};
exports.filterList = filterList;
const filterLinkedList = (list, filterTextValue) => {
    var _a;
    const itemsList = filterTextValue
        ? (_a = list === null || list === void 0 ? void 0 : list.items) === null || _a === void 0 ? void 0 : _a.filter(item => (0, object_1.anyPropertyContainsText)(item.departureFlight, filterTextValue) ||
            (0, object_1.anyPropertyContainsText)(item.arrivalFlight, filterTextValue))
        : list === null || list === void 0 ? void 0 : list.items;
    return {
        count: list.count,
        items: itemsList,
    };
};
exports.filterLinkedList = filterLinkedList;
