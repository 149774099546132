"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable indent */
const layout_1 = require("@chakra-ui/layout");
const react_1 = __importStar(require("react"));
const gantt_1 = require("@/constants/gantt");
const types_1 = require("@/screens/flight-control/types");
const segment_details_nodes_1 = require("./segment-details-nodes");
function GanttSegmentDetails(props) {
    const { segment, segmentDuration, segmentIconColor, segmentDetailsTools } = props;
    const { getNecessarySpaceForNodes } = segmentDetailsTools;
    const mainIcon = (react_1.default.createElement(segment_details_nodes_1.GanttSegmentMainIcon, { segment: segment, key: "main-icon", segmentIconColor: segmentIconColor }));
    const { data, type } = segment;
    const { [gantt_1.GanttSegmentFlightFields.identity]: identity, [gantt_1.GanttSegmentFlightFields.flightNumber]: flightNumber, [gantt_1.GanttSegmentFlightFields.aircraftRegistration]: aircraftRegistration, [gantt_1.GanttSegmentFlightFields.aircraftTypeIcao]: aircraftTypeIcao, [gantt_1.GanttSegmentFlightFields.aircraftTypeIata]: aircraftTypeIata, [gantt_1.GanttSegmentFlightFields.linkedFlight]: linkedFlight, [gantt_1.GanttSegmentFlightFields.isGeneralAviation]: isGeneralAviation, } = data;
    const { [gantt_1.GanttSegmentFlightFields.identity]: linkedIdentity, [gantt_1.GanttSegmentFlightFields.flightNumber]: linkedFlightNumber, [gantt_1.GanttSegmentFlightFields.aircraftRegistration]: linkedAircraftRegistration, [gantt_1.GanttSegmentFlightFields.aircraftTypeIcao]: linkedAircraftTypeIcao, [gantt_1.GanttSegmentFlightFields.aircraftTypeIata]: linkedAircraftTypeIata, [gantt_1.GanttSegmentFlightFields.isGeneralAviation]: linkedIsGeneralAviation, } = linkedFlight || {
        [gantt_1.GanttSegmentFlightFields.identity]: undefined,
        [gantt_1.GanttSegmentFlightFields.flightNumber]: undefined,
        [gantt_1.GanttSegmentFlightFields.aircraftRegistration]: undefined,
        [gantt_1.GanttSegmentFlightFields.aircraftTypeIcao]: undefined,
        [gantt_1.GanttSegmentFlightFields.aircraftTypeIata]: undefined,
        [gantt_1.GanttSegmentFlightFields.isGeneralAviation]: undefined,
    };
    const aircraftData = aircraftRegistration || aircraftTypeIcao || aircraftTypeIata;
    const linkedAircraftData = linkedAircraftRegistration || linkedAircraftTypeIcao || linkedAircraftTypeIata;
    const isLinked = !!linkedFlight;
    const childNodes = (0, react_1.useMemo)(() => {
        if (type === types_1.GanttSegmentType.construction)
            return [
                { node: mainIcon, rating: 2 /* essential */ },
                {
                    node: react_1.default.createElement(segment_details_nodes_1.GanttConstructionLabel, { mirrored: false, key: "construction-label" }),
                    rating: 1 /* good */,
                },
                {
                    node: react_1.default.createElement(segment_details_nodes_1.GanttConstructionLabel, { mirrored: true, key: "mirrored-construction-label" }),
                    rating: 0 /* hideable */,
                },
                {
                    node: react_1.default.createElement(segment_details_nodes_1.GanttMirroredConstructionIcon, { key: "mirrored-construction-icon" }),
                    rating: 0 /* hideable */,
                },
            ];
        const hasStandardRequest = (data === null || data === void 0 ? void 0 : data[gantt_1.GanttSegmentFlightFields.hasStandardPnaeRequests]) ||
            (linkedFlight === null || linkedFlight === void 0 ? void 0 : linkedFlight[gantt_1.GanttSegmentFlightFields.hasStandardPnaeRequests]);
        const hasAmbuliftRequest = (data === null || data === void 0 ? void 0 : data[gantt_1.GanttSegmentFlightFields.hasAmbuliftRequests]) ||
            (linkedFlight === null || linkedFlight === void 0 ? void 0 : linkedFlight[gantt_1.GanttSegmentFlightFields.hasAmbuliftRequests]);
        const nodes = [
            !isLinked ? { node: mainIcon, rating: 1 /* good */ } : null,
            {
                node: (react_1.default.createElement(segment_details_nodes_1.GanttFlightIdentityLabel, { identity: isGeneralAviation ? aircraftData : identity, key: "identity" })),
                rating: 2 /* essential */,
            },
            isLinked ? { node: mainIcon, rating: 2 /* essential */ } : null,
            isLinked
                ? {
                    node: (react_1.default.createElement(segment_details_nodes_1.GanttFlightIdentityLabel, { identity: linkedIsGeneralAviation ? linkedAircraftData : linkedIdentity, key: "linked-identity" })),
                    rating: 1 /* good */,
                }
                : null,
            {
                node: hasStandardRequest && (react_1.default.createElement(segment_details_nodes_1.GanttFlightPnaeIcon, { segmentIconColor: segmentIconColor, key: "pnae" })),
                rating: 0 /* hideable */,
            },
            {
                node: hasAmbuliftRequest && (react_1.default.createElement(segment_details_nodes_1.GanttFlightPnaeIcon, { segmentIconColor: segmentIconColor, isAmbulift: true, key: "ambulift" })),
                rating: 0 /* hideable */,
            },
            {
                node: !isGeneralAviation && (react_1.default.createElement(segment_details_nodes_1.GanttFlightAircraftLabel, { aircraftData: aircraftData, key: "aircraft" })),
                rating: 0 /* hideable */,
            },
        ];
        const filteredNodes = nodes.filter(node => node !== null);
        return filteredNodes;
    }, [segment, segmentIconColor]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const oneIntervalSegmentNode = (0, react_1.useMemo)(() => mainIcon, [
        segment,
        segmentIconColor,
    ]);
    const isScreenFullHdOrBigger = window.innerWidth >= 1920;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const abbreviatedSegmentNodes = (0, react_1.useMemo)(() => {
        const flightIdentity = isScreenFullHdOrBigger && segmentDuration >= 3 ? (identity === null || identity === void 0 ? void 0 : identity[0]) + flightNumber : flightNumber;
        const linkedFlightIdentity = isScreenFullHdOrBigger && segmentDuration >= 3
            ? (linkedIdentity === null || linkedIdentity === void 0 ? void 0 : linkedIdentity[0]) + linkedFlightNumber
            : linkedFlightNumber;
        const aircraftDataIsSame = aircraftData === linkedAircraftData;
        const linkedFlightAircraftData = aircraftDataIsSame ? null : linkedAircraftData;
        return (react_1.default.createElement(segment_details_nodes_1.GanttAbbreviatedSegmentNodes, { key: "identities", flightIdentity: isGeneralAviation ? aircraftData : flightIdentity, linkedFlightIdentity: linkedIsGeneralAviation ? linkedFlightAircraftData : linkedFlightIdentity }));
    }, [segment, segmentIconColor]);
    const allNodes = childNodes.filter(item => item.node !== false).map(i => i.node);
    const nodesGoodToHave = childNodes
        .filter(item => item.node !== false && item.rating !== 0 /* hideable */)
        .map(i => i.node);
    const essentialNodes = childNodes
        .filter(item => item.node !== false && item.rating === 2 /* essential */)
        .map(i => i.node);
    if (type !== types_1.GanttSegmentType.construction &&
        ((!isScreenFullHdOrBigger && segmentDuration <= 2) ||
            (isScreenFullHdOrBigger && segmentDuration < 2)))
        return react_1.default.createElement(layout_1.HStack, { spacing: 3 }, oneIntervalSegmentNode);
    const necessarySpaceForAbbreviatedSegmentNodes = isScreenFullHdOrBigger ? 4 : 6;
    if (type !== types_1.GanttSegmentType.construction &&
        isLinked &&
        segmentDuration <= necessarySpaceForAbbreviatedSegmentNodes)
        return react_1.default.createElement(layout_1.HStack, { spacing: 0.5 }, abbreviatedSegmentNodes);
    if (segmentDuration >= getNecessarySpaceForNodes(isScreenFullHdOrBigger, allNodes.length)) {
        return react_1.default.createElement(layout_1.HStack, { spacing: 3 }, allNodes);
    }
    if (segmentDuration >= getNecessarySpaceForNodes(isScreenFullHdOrBigger, nodesGoodToHave.length)) {
        return react_1.default.createElement(layout_1.HStack, { spacing: 3 }, nodesGoodToHave);
    }
    return react_1.default.createElement(layout_1.HStack, { spacing: 3 }, essentialNodes);
}
exports.default = GanttSegmentDetails;
