"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThreadClosureReasons = exports.NotificationActionTypes = exports.ThreadNotificationFieldNames = void 0;
var ThreadNotificationFieldNames;
(function (ThreadNotificationFieldNames) {
    ThreadNotificationFieldNames["unreadMessagesCount"] = "unread_messages_count";
    ThreadNotificationFieldNames["tracking"] = "tracking";
    ThreadNotificationFieldNames["threadId"] = "thread_id";
    ThreadNotificationFieldNames["threadDisplayName"] = "thread_display_name";
    ThreadNotificationFieldNames["mostRecentMessageTimestamp"] = "most_recent_message_timestamp";
    ThreadNotificationFieldNames["flightId"] = "flight_id";
    ThreadNotificationFieldNames["flightIdentity"] = "flight_identity";
    ThreadNotificationFieldNames["flightAirline"] = "flight_airline";
    ThreadNotificationFieldNames["flightScheduledDatetime"] = "flight_scheduled_datetime";
    ThreadNotificationFieldNames["threadVendorId"] = "thread_vendor_id";
    ThreadNotificationFieldNames["ambuliftRequestId"] = "ambulift_request_id";
    ThreadNotificationFieldNames["lastMessageSenderName"] = "last_message_sender_name";
    ThreadNotificationFieldNames["lastMessagePreview"] = "last_message_preview";
    ThreadNotificationFieldNames["flightIsArrival"] = "flight_is_arrival";
    ThreadNotificationFieldNames["mainAirportIcao"] = "main_airport_icao";
})(ThreadNotificationFieldNames = exports.ThreadNotificationFieldNames || (exports.ThreadNotificationFieldNames = {}));
var NotificationActionTypes;
(function (NotificationActionTypes) {
    NotificationActionTypes["insertNewThread"] = "insertNewThread";
    NotificationActionTypes["markUnread"] = "markUnread";
    NotificationActionTypes["markRead"] = "markRead";
    NotificationActionTypes["setTracking"] = "setTracking";
    NotificationActionTypes["initialize"] = "initialize";
    NotificationActionTypes["removeIrrelevantThread"] = "removeIrrelevantThread";
})(NotificationActionTypes = exports.NotificationActionTypes || (exports.NotificationActionTypes = {}));
var ThreadClosureReasons;
(function (ThreadClosureReasons) {
    ThreadClosureReasons["flightFinished"] = "flight_finished";
    ThreadClosureReasons["flightCancelled"] = "flight_cancelled";
    ThreadClosureReasons["flightDeleted"] = "flight_deleted";
    ThreadClosureReasons["ambuliftRequestFinished"] = "ambulift_request_finished";
    ThreadClosureReasons["ambuliftRequestCancelled"] = "ambulift_request_cancelled";
})(ThreadClosureReasons = exports.ThreadClosureReasons || (exports.ThreadClosureReasons = {}));
