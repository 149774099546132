"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFlightFieldDisabled = void 0;
const react_1 = require("react");
const utils_1 = require("@/components/list-flight/utils");
const flight_1 = require("@/constants/flight");
const userPermission_1 = require("@/constants/userPermission");
const permission_1 = require("@/helpers/permission");
const scoreFields = [
    flight_1.FlightFieldNames.scheduledArrivalDatetime,
    flight_1.FlightFieldNames.scheduledDepartureDatetime,
    flight_1.FlightFieldNames.airline,
    flight_1.FlightFieldNames.aircraftType,
    flight_1.FlightFieldNames.flightType,
    flight_1.FlightFieldNames.serviceType,
    flight_1.FlightFieldNames.originAirport,
    flight_1.FlightFieldNames.destinationAirport,
    flight_1.FlightFieldNames.divertedDestinationAirport,
    flight_1.FlightFieldNames.divertIndicator,
    flight_1.FlightFieldNames.flightNumber,
    flight_1.FlightFieldNames.identity,
    flight_1.FlightFieldNames.vias,
];
const useFlightFieldDisabled = (params) => {
    const { item, user, isArrival, initialValues } = params;
    const isBlocked = (0, permission_1.useBlocked)(item, user);
    const isStandDisabled = (0, react_1.useCallback)(() => {
        const departureFlightHasStandAndLinkedFlight = !isArrival && (item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.stand]) && (item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.linkedFlight]);
        return departureFlightHasStandAndLinkedFlight;
    }, [isArrival, item]);
    const exclusiveDisabledRules = (0, react_1.useMemo)(() => ({
        [flight_1.FlightFieldNames.routeType]: !!(item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.id]),
        [flight_1.FlightFieldNames.linkedFlight]: (0, permission_1.getDisabledFromPermission)(user, flight_1.FlightFieldNames.linkedFlight) ||
            (!!(initialValues === null || initialValues === void 0 ? void 0 : initialValues[flight_1.FlightFieldNames.linkedFlight]) && isBlocked) ||
            (0, utils_1.checkCancelledFlightStatus)(item) ||
            (0, utils_1.checkDivertedFlightStatus)(item),
    }), [isBlocked, item, user, initialValues]);
    const shouldDisableScoreFields = item &&
        item[flight_1.FlightFieldNames.scoreImportHistory] &&
        !(0, permission_1.userHasFlightFieldPermission)(user, userPermission_1.PermissionAction.MANAGE_SCORE_FLIGHT_FIELDS);
    const additionalDisabledRules = (0, react_1.useMemo)(() => ({
        [flight_1.FlightFieldNames.isGeneralAviation]: !!(item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.id]),
        [flight_1.FlightFieldNames.stand]: isStandDisabled(),
        [flight_1.FlightFieldNames.airline]: item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.isGeneralAviation],
        [flight_1.FlightFieldNames.flightNumber]: item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.isGeneralAviation],
        [flight_1.FlightFieldNames.originAirport]: !isArrival,
        [flight_1.FlightFieldNames.destinationAirport]: isArrival,
        [flight_1.FlightFieldNames.divertedDestinationAirport]: (item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.currentStatus]) !== flight_1.FlightStatus.diverted ||
            (initialValues === null || initialValues === void 0 ? void 0 : initialValues[flight_1.FlightFieldNames.divertedDestinationAirport]),
        [flight_1.FlightFieldNames.currentEstimatedTime]: (0, utils_1.checkCancelledFlightStatus)(item),
        [flight_1.FlightFieldNames.actualOffBlockTime]: (0, utils_1.checkCancelledFlightStatus)(item),
        [flight_1.FlightFieldNames.actualTakeOffTime]: (0, utils_1.checkCancelledFlightStatus)(item),
        [flight_1.FlightFieldNames.actualTouchdownTime]: (0, utils_1.checkCancelledFlightStatus)(item),
        [flight_1.FlightFieldNames.actualInBlockTime]: (0, utils_1.checkCancelledFlightStatus)(item),
        [flight_1.FlightFieldNames.aircraft]: !isArrival && !(item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.isGeneralAviation]),
        [flight_1.FlightFieldNames.aircraftType]: !!(item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.aircraft]),
    }), [item, isStandDisabled, isArrival, initialValues]);
    const generateDisabledMap = () => {
        const fieldDisabledMap = {};
        if (!item)
            return fieldDisabledMap;
        Object.values(flight_1.FlightFieldNames).forEach((field) => {
            if (field in exclusiveDisabledRules) {
                fieldDisabledMap[field] = !!exclusiveDisabledRules[field];
                return;
            }
            const additionalRulesDisableField = Boolean(additionalDisabledRules[field]);
            const scoreRulesDisableField = Boolean(scoreFields.find(scoreField => scoreField === field) && shouldDisableScoreFields);
            fieldDisabledMap[field] =
                isBlocked ||
                    (0, permission_1.getDisabledFromPermission)(user, field) ||
                    scoreRulesDisableField ||
                    additionalRulesDisableField;
        });
        return fieldDisabledMap;
    };
    const isFieldDisabled = (field) => { var _a, _b; return (_b = (_a = generateDisabledMap()) === null || _a === void 0 ? void 0 : _a[field]) !== null && _b !== void 0 ? _b : false; };
    return { isFieldDisabled, isBlocked };
};
exports.useFlightFieldDisabled = useFlightFieldDisabled;
exports.default = exports.useFlightFieldDisabled;
