"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_AIRPORT = exports.removeSavedAirport = exports.hasSavedAirport = exports.getSavedAirportCode = exports.updateSavedAirport = void 0;
const auth_1 = require("@/auth");
const CURRENT_AIRPORT_KEY = 'current-airport';
const DEFAULT_AIRPORT = 'CCR';
exports.DEFAULT_AIRPORT = DEFAULT_AIRPORT;
const removeSavedAirport = () => {
    localStorage.removeItem(CURRENT_AIRPORT_KEY);
};
exports.removeSavedAirport = removeSavedAirport;
const updateSavedAirport = (airportCode) => {
    if (!airportCode)
        return removeSavedAirport();
    return localStorage.setItem(CURRENT_AIRPORT_KEY, airportCode);
};
exports.updateSavedAirport = updateSavedAirport;
const getSavedAirportCode = () => {
    if (!(0, auth_1.isAuthenticated)())
        return null;
    return localStorage.getItem(CURRENT_AIRPORT_KEY);
};
exports.getSavedAirportCode = getSavedAirportCode;
const hasSavedAirport = () => {
    const currentAirport = localStorage.getItem(CURRENT_AIRPORT_KEY);
    return !!currentAirport;
};
exports.hasSavedAirport = hasSavedAirport;
