"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const navbarData_1 = require("@/services/navbarData");
const useUserPermissions_1 = require("./useUserPermissions");
const useUserInfo = () => {
    const [userData, setUserData] = (0, react_1.useState)(null);
    const [loadingUserData, setLoadingUserData] = (0, react_1.useState)(false);
    const permissionProps = (0, useUserPermissions_1.useUserPermissions)({ user: userData });
    const fetchUserData = () => {
        setLoadingUserData(true);
        return (0, navbarData_1.getUserInfo)()
            .then(response => {
            if (response.status === 200) {
                const userInfo = response.data;
                setUserData(userInfo);
            }
        })
            .finally(() => setLoadingUserData(false));
    };
    const onUserLogout = () => setUserData(null);
    const user = (0, react_1.useMemo)(() => (userData ? { ...userData, permissions: permissionProps } : null), [
        userData,
        permissionProps,
    ]);
    return { user, fetchUserData, onUserLogout, loadingUserData };
};
exports.default = useUserInfo;
