"use strict";
// https://github.com/JedWatson/react-select/issues/2920#issuecomment-422992520
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectSimpleValue = void 0;
function flatten(arr) {
    return arr === null || arr === void 0 ? void 0 : arr.reduce((acc, val) => (Array.isArray(val.options) ? acc.concat(flatten(val.options)) : acc.concat(val)), []);
}
const clean = (x) => x.trim();
const toArray = (str) => str.split(',').map(clean);
function getValue(opts, val, getOptVal, isMulti) {
    if (val === undefined || val === null)
        return val;
    const options = flatten(opts);
    const value = isMulti
        ? options === null || options === void 0 ? void 0 : options.filter(o => toArray(val).includes(getOptVal(o)))
        : options === null || options === void 0 ? void 0 : options.find(o => getOptVal(o) === val);
    return value;
}
const defaultGetOptionValue = (opt) => opt.value;
function SelectSimpleValue({ children, defaultValue: simpleDefault, getOptionValue = defaultGetOptionValue, isMulti = false, options, value: simpleValue, }) {
    const value = getValue(options, simpleValue, getOptionValue, isMulti);
    const defaultValue = getValue(options, simpleDefault, getOptionValue, isMulti);
    return children({ defaultValue, getOptionValue, isMulti, options, value });
}
exports.SelectSimpleValue = SelectSimpleValue;
exports.default = SelectSimpleValue;
