"use strict";
/* eslint-disable camelcase */
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrivalFields = exports.departureFields = exports.flightCreationPlaceholderValues = void 0;
const flight_1 = require("@/constants/flight");
const types_1 = require("@/constants/types");
exports.flightCreationPlaceholderValues = {
    current_estimated_time: '',
    current_status: null,
    vias: [],
    flight_pnae_options: [],
    airport_selected_resources: [],
    flight_codeshare_airlines: [],
    gate: null,
    gate_open_datetime: null,
    gate_close_datetime: null,
    identity: '',
    flight_number: '',
    scheduled_departure_datetime: '',
    scheduled_arrival_datetime: '',
    return_indicator: false,
    passengers_pnae_count: 0,
    passengers_count: null,
    is_public_flight: true,
    actual_in_block_datetime: '',
    actual_touchdown_datetime: '',
    actual_off_block_datetime: '',
    actual_take_off_datetime: '',
    divert_indicator: false,
    airline: null,
    aircraft: null,
    origin_airport: null,
    destination_airport: null,
    service_type: null,
    flight_type: null,
    reason_code: null,
    linked_flight: null,
    runway: null,
    stand: null,
    passenger_terminal_arrival: null,
    aircraft_terminal_arrival: null,
    flight_statuses: [],
    route_type: types_1.FlightRouteType.arrival,
    return_reason: '',
    is_general_aviation: false,
    ambulift_requests: [],
    standard_pnae_requests: [],
    unsaved_pnae_requests: [],
};
exports.departureFields = [
    flight_1.FlightFieldNames.currentEstimatedTime,
    flight_1.FlightFieldNames.destinationAirport,
    flight_1.FlightFieldNames.scheduledDepartureDatetime,
    flight_1.FlightFieldNames.actualOffBlockTime,
    flight_1.FlightFieldNames.actualTakeOffTime,
    flight_1.FlightFieldNames.gate,
    flight_1.FlightFieldNames.openTimeGate,
    flight_1.FlightFieldNames.closeTimeGate,
];
exports.arrivalFields = [
    flight_1.FlightFieldNames.currentEstimatedTime,
    flight_1.FlightFieldNames.originAirport,
    flight_1.FlightFieldNames.scheduledArrivalDatetime,
    flight_1.FlightFieldNames.actualInBlockTime,
    flight_1.FlightFieldNames.actualTouchdownTime,
    flight_1.FlightFieldNames.reclaimBelt,
    flight_1.FlightFieldNames.beltOpenDatetime,
    flight_1.FlightFieldNames.beltCloseDatetime,
];
