"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardPnaeAssistanceRequestUnsaved = exports.baseRequestFieldNames = void 0;
const react_1 = require("@chakra-ui/react");
const react_2 = __importStar(require("react"));
const components_1 = require("@/components");
const components_2 = require("./components");
exports.baseRequestFieldNames = {
    pnaeType: 'pnae_type',
    airportResource: 'requested_airport_resource',
    necessaryTime: 'scheduled_at',
    comment: 'comment',
    hasAmbulift: 'has_ambulift',
};
const CardPnaeAssistanceRequestUnsaved = (props) => {
    const { title, values, after, fields, setFieldValue } = props;
    const hasAmbulift = values === null || values === void 0 ? void 0 : values[exports.baseRequestFieldNames.hasAmbulift];
    (0, react_2.useEffect)(() => {
        if (hasAmbulift) {
            setFieldValue(fields.airportResource, '');
        }
        else {
            setFieldValue(fields.necessaryTime, '');
            setFieldValue(fields.comment, '');
        }
    }, [hasAmbulift]);
    return (react_2.default.createElement(components_1.CardCollapsible, { title: title, after: after },
        react_2.default.createElement(react_2.default.Fragment, null,
            react_2.default.createElement(components_2.GridCardRequest, null,
                react_2.default.createElement(react_1.GridItem, null,
                    react_2.default.createElement(components_2.PnaeOptionSelect, { ...props })),
                react_2.default.createElement(react_1.GridItem, null,
                    react_2.default.createElement(components_2.HasAmbuliftField, { ...props, hasAmbulift: values === null || values === void 0 ? void 0 : values[exports.baseRequestFieldNames.hasAmbulift] })),
                react_2.default.createElement(react_1.GridItem, null,
                    hasAmbulift && react_2.default.createElement(components_2.NecessaryTimeField, { ...props }),
                    hasAmbulift === false && react_2.default.createElement(components_2.AirportResourceField, { ...props }))),
            hasAmbulift && (react_2.default.createElement(components_2.GridCardRequest, { columns: 1 },
                react_2.default.createElement(react_1.GridItem, null,
                    react_2.default.createElement(components_2.CommentsField, { ...props })))))));
};
exports.CardPnaeAssistanceRequestUnsaved = CardPnaeAssistanceRequestUnsaved;
exports.default = exports.CardPnaeAssistanceRequestUnsaved;
