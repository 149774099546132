"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandType = exports.GanttStandFields = exports.GanttSegmentFlightFields = exports.GanttSegmentFields = void 0;
var GanttSegmentFields;
(function (GanttSegmentFields) {
    GanttSegmentFields["id"] = "id";
    GanttSegmentFields["type"] = "type";
    GanttSegmentFields["startOfStay"] = "start_of_stay";
    GanttSegmentFields["endOfStay"] = "end_of_stay";
    GanttSegmentFields["data"] = "data";
})(GanttSegmentFields = exports.GanttSegmentFields || (exports.GanttSegmentFields = {}));
var GanttSegmentFlightFields;
(function (GanttSegmentFlightFields) {
    GanttSegmentFlightFields["id"] = "id";
    GanttSegmentFlightFields["isArrival"] = "is_arrival";
    GanttSegmentFlightFields["identity"] = "identity";
    GanttSegmentFlightFields["flightNumber"] = "flight_number";
    GanttSegmentFlightFields["airline"] = "airline";
    GanttSegmentFlightFields["aircraftRegistration"] = "aircraft_registration";
    GanttSegmentFlightFields["aircraftTypeIcao"] = "aircraft_type_icao_type";
    GanttSegmentFlightFields["aircraftTypeIata"] = "aircraft_type_iata_type";
    GanttSegmentFlightFields["hasAmbuliftRequests"] = "has_ambulift_requests";
    GanttSegmentFlightFields["hasStandardPnaeRequests"] = "has_standard_pnae_requests";
    GanttSegmentFlightFields["isGeneralAviation"] = "is_general_aviation";
    GanttSegmentFlightFields["linkedFlight"] = "linked_flight";
    GanttSegmentFlightFields["stand"] = "stand";
    GanttSegmentFlightFields["currentStatus"] = "current_status";
    GanttSegmentFlightFields["currentStatusCode"] = "current_status_code";
    GanttSegmentFlightFields["arrivalFlightId"] = "arrival_flight_id";
    GanttSegmentFlightFields["entranceMovement"] = "entrance_movement";
    GanttSegmentFlightFields["exitMovement"] = "exit_movement";
})(GanttSegmentFlightFields = exports.GanttSegmentFlightFields || (exports.GanttSegmentFlightFields = {}));
var GanttStandFields;
(function (GanttStandFields) {
    GanttStandFields["id"] = "id";
    GanttStandFields["position"] = "position";
    GanttStandFields["type"] = "type";
    GanttStandFields["typeRepresentation"] = "type_representation";
    GanttStandFields["isAvailable"] = "is_available";
})(GanttStandFields = exports.GanttStandFields || (exports.GanttStandFields = {}));
var StandType;
(function (StandType) {
    StandType["BRIDGE"] = "P";
    StandType["REMOTE"] = "R";
    StandType["MAMUTH"] = "M";
    StandType["HANGAR"] = "H";
})(StandType = exports.StandType || (exports.StandType = {}));
