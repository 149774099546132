"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TowingMovementType = exports.TowingFields = void 0;
var TowingFields;
(function (TowingFields) {
    TowingFields["AIRLINE"] = "airline";
    TowingFields["LOGO_PATH"] = "logo_url";
    TowingFields["ICAO_CODE"] = "icao_code";
    TowingFields["AIRCRAFT"] = "aircraft";
    TowingFields["ARRIVAL_FLIGHT"] = "arrival_flight_identity";
    TowingFields["STAND"] = "stand";
    TowingFields["POSITION"] = "position";
    TowingFields["IS_FINISHED"] = "is_finished";
    TowingFields["MOVEMENTS"] = "movements";
    TowingFields["ENTRANCE_DATETIME"] = "entrance_datetime";
    TowingFields["ENTRANCE_MOVEMENT"] = "entrance_movement";
    TowingFields["EXIT_DATETIME"] = "exit_datetime";
    TowingFields["EXIT_MOVEMENT"] = "exit_movement";
    TowingFields["TOTAL_DURATION"] = "total_duration";
    TowingFields["IS_GENERAL_AVIATION"] = "is_general_aviation";
    TowingFields["AIRPORT_ICAO"] = "main_airport_icao";
    TowingFields["TOWING"] = "towing";
    TowingFields["LAST_STAND"] = "last_stand";
    TowingFields["CURRENT_STAND"] = "current_stand";
})(TowingFields = exports.TowingFields || (exports.TowingFields = {}));
var TowingMovementType;
(function (TowingMovementType) {
    TowingMovementType["LANDING"] = "Landing";
    TowingMovementType["TAKE_OFF"] = "Take off";
    TowingMovementType["TOWING"] = "Towing";
})(TowingMovementType = exports.TowingMovementType || (exports.TowingMovementType = {}));
