"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@chakra-ui/react");
const react_2 = require("react");
const useStandardToast = () => {
    const toast = (0, react_1.useToast)();
    const showErrorToast = (0, react_2.useCallback)((message) => toast({
        title: message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
    }), [toast]);
    const showSuccessToast = (0, react_2.useCallback)((message) => toast({
        title: message,
        status: 'success',
        position: 'top-right',
        isClosable: true,
    }), [toast]);
    const showCustomToast = (0, react_2.useCallback)((message, status, position, isClosable) => toast({
        title: message,
        status,
        position: position !== null && position !== void 0 ? position : 'top-right',
        isClosable: isClosable !== null && isClosable !== void 0 ? isClosable : true,
    }), [toast]);
    return {
        showSuccessToast,
        showErrorToast,
        showCustomToast,
    };
};
exports.default = useStandardToast;
