"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGanttAircraftTypeRepresentation = exports.getGanttAircraftRepresentation = exports.getFlightCurrentAirline = exports.isFlightStatusFinished = exports.formatAircraftType = exports.formatAircraftTypeCode = void 0;
const flight_1 = require("@/constants/flight");
const gantt_1 = require("@/constants/gantt");
const formatAircraftTypeCode = (aircraftType) => `${aircraftType.iata_type ? aircraftType.iata_type : ''}${aircraftType.iata_type && aircraftType.icao_type ? '/' : ''}${aircraftType.icao_type ? `${aircraftType.icao_type}` : ''}`;
exports.formatAircraftTypeCode = formatAircraftTypeCode;
const formatAircraftType = (item) => {
    const aircraftType = item === null || item === void 0 ? void 0 : item[flight_1.FlightFieldNames.aircraftType];
    const emptyValue = '-';
    if (!aircraftType)
        return emptyValue;
    return (0, exports.formatAircraftTypeCode)(aircraftType);
};
exports.formatAircraftType = formatAircraftType;
const isFlightStatusFinished = (flightStatus) => Boolean(flightStatus &&
    (flightStatus.code === flight_1.FlightStatus.cancelled ||
        flightStatus.code === flight_1.FlightStatus.flightClosed));
exports.isFlightStatusFinished = isFlightStatusFinished;
const getFlightCurrentAirline = (airlines, flight) => {
    const currentAirportAirline = airlines === null || airlines === void 0 ? void 0 : airlines.find((airline) => { var _a; return ((_a = airline === null || airline === void 0 ? void 0 : airline[flight_1.FieldOptions.AIRLINE]) === null || _a === void 0 ? void 0 : _a.id) === (flight === null || flight === void 0 ? void 0 : flight[flight_1.FlightFieldNames.airline]); });
    return currentAirportAirline === null || currentAirportAirline === void 0 ? void 0 : currentAirportAirline[flight_1.FieldOptions.AIRLINE];
};
exports.getFlightCurrentAirline = getFlightCurrentAirline;
const getGanttAircraftRepresentation = (flight) => flight === null || flight === void 0 ? void 0 : flight[gantt_1.GanttSegmentFlightFields.aircraftRegistration];
exports.getGanttAircraftRepresentation = getGanttAircraftRepresentation;
const getGanttAircraftTypeRepresentation = (flight) => (flight === null || flight === void 0 ? void 0 : flight[gantt_1.GanttSegmentFlightFields.aircraftTypeIcao]) ||
    (flight === null || flight === void 0 ? void 0 : flight[gantt_1.GanttSegmentFlightFields.aircraftTypeIata]);
exports.getGanttAircraftTypeRepresentation = getGanttAircraftTypeRepresentation;
