"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fields = void 0;
var Fields;
(function (Fields) {
    Fields["startDate"] = "startDate";
    Fields["endDate"] = "endDate";
    Fields["searchText"] = "search";
    Fields["status"] = "status";
    Fields["originAirport"] = "originAirport";
    Fields["destinationAirport"] = "destinationAirport";
    Fields["aircraft"] = "aircraft";
    Fields["airline"] = "airline";
    Fields["pnae"] = "pnae";
    Fields["hasAmbulift"] = "hasAmbulift";
    Fields["hasStandardPnae"] = "hasPnae";
    Fields["showUnavailableStands"] = "showUnavailableStands";
})(Fields = exports.Fields || (exports.Fields = {}));
