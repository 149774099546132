"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userIsAirlineOperator = exports.GanttSettingsFields = exports.GanttAirlineDisplaySettingsFields = exports.GanttStandDisplaySettingsFields = void 0;
const userPermission_1 = require("./userPermission");
var GanttStandDisplaySettingsFields;
(function (GanttStandDisplaySettingsFields) {
    GanttStandDisplaySettingsFields["standType"] = "stand_type";
    GanttStandDisplaySettingsFields["displayColor"] = "display_color";
})(GanttStandDisplaySettingsFields = exports.GanttStandDisplaySettingsFields || (exports.GanttStandDisplaySettingsFields = {}));
var GanttAirlineDisplaySettingsFields;
(function (GanttAirlineDisplaySettingsFields) {
    GanttAirlineDisplaySettingsFields["airlineId"] = "airline_id";
    GanttAirlineDisplaySettingsFields["displayColor"] = "display_color";
})(GanttAirlineDisplaySettingsFields = exports.GanttAirlineDisplaySettingsFields || (exports.GanttAirlineDisplaySettingsFields = {}));
var GanttSettingsFields;
(function (GanttSettingsFields) {
    GanttSettingsFields["initialIntervalSize"] = "initial_interval_size";
    GanttSettingsFields["airlineDisplaySettings"] = "airline_display_settings";
    GanttSettingsFields["standDisplaySettings"] = "stand_display_settings";
    GanttSettingsFields["constructionDisplayColor"] = "construction_display_color";
    GanttSettingsFields["automaticRefreshScreenTime"] = "automatic_refresh_screen_time";
})(GanttSettingsFields = exports.GanttSettingsFields || (exports.GanttSettingsFields = {}));
function userIsAirlineOperator(user) {
    return (user.permissionGroups.includes(userPermission_1.PermissionGroups.AIRLINE_OPERATOR) &&
        !user.permissionGroups.includes(userPermission_1.PermissionGroups.AIRPORT_OPERATOR) &&
        !user.permissionGroups.includes(userPermission_1.PermissionGroups.AIRPORT_SUPERVISOR) &&
        !user.isAdmin);
}
exports.userIsAirlineOperator = userIsAirlineOperator;
