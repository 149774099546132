"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logout = exports.login = exports.getTokenRefresh = exports.getTokenAccess = exports.isAuthenticated = exports.TOKEN_REFRESH = exports.TOKEN_ACCESS = void 0;
exports.TOKEN_ACCESS = 'auth-token-access';
exports.TOKEN_REFRESH = 'auth-token-refresh';
const isAuthenticated = () => localStorage.getItem(exports.TOKEN_ACCESS) != null;
exports.isAuthenticated = isAuthenticated;
const getTokenAccess = () => localStorage.getItem(exports.TOKEN_ACCESS);
exports.getTokenAccess = getTokenAccess;
const getTokenRefresh = () => localStorage.getItem(exports.TOKEN_REFRESH);
exports.getTokenRefresh = getTokenRefresh;
const login = (token) => {
    localStorage.setItem(exports.TOKEN_ACCESS, token.access);
    localStorage.setItem(exports.TOKEN_REFRESH, token.refresh);
};
exports.login = login;
const logout = () => {
    localStorage.removeItem(exports.TOKEN_ACCESS);
    localStorage.removeItem(exports.TOKEN_REFRESH);
};
exports.logout = logout;
