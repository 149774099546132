"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildInitialFilters = exports.GanttData = void 0;
const components_1 = require("@/components");
const filter_flights_1 = require("@/components/filter-flights");
const filter_gantt_1 = require("@/components/filter-gantt");
const filter_towing_1 = require("@/components/filter-towing");
const types_1 = require("@/constants/types");
const filters_1 = require("./filters");
const index_1 = require("./listing/index");
const search_1 = require("./search");
const types_2 = require("./types");
const ArrivalTabData = {
    filters: {
        getInitialFiltersValues: filter_flights_1.getInitialFilterValues,
        component: filters_1.ArrivalTabFilter,
    },
    search: {
        handleQueryChange: (searchData, listingData) => (0, search_1.filterList)(listingData, searchData),
        component: components_1.FlightSearchBar,
    },
    listing: {
        onItemUpdate: index_1.buildArrivalOnItemUpdate,
        initialData: types_2.emptyListData,
        loadData: index_1.loadArrivalTabData,
        appendNewData: index_1.appendSingleListNewPageData,
        component: index_1.ArrivalTabListing,
    },
};
const DepartureTabData = {
    filters: {
        getInitialFiltersValues: filter_flights_1.getInitialFilterValues,
        component: filters_1.DepartureTabFilter,
    },
    listing: {
        onItemUpdate: index_1.buildDepartureOnItemUpdate,
        initialData: types_2.emptyListData,
        loadData: index_1.loadDepartureTabData,
        appendNewData: index_1.appendSingleListNewPageData,
        component: index_1.DepartureTabListing,
    },
    search: {
        handleQueryChange: (searchData, listingData) => (0, search_1.filterList)(listingData, searchData),
        component: components_1.FlightSearchBar,
    },
};
const BothTabData = {
    filters: {
        getInitialFiltersValues: filter_flights_1.getInitialFilterValues,
        component: filters_1.BothTabFilter,
    },
    listing: {
        onItemUpdate: index_1.buildBothOnItemUpdate,
        initialData: types_2.emptyBothFlightList,
        loadData: index_1.loadBothTabData,
        appendNewData: index_1.appendBothListsNewPageData,
        component: index_1.BothTabListing,
    },
    search: {
        handleQueryChange: (searchData, listingData) => ({
            arrivalFlights: (0, search_1.filterList)(listingData.arrivalFlights, searchData),
            departureFlights: (0, search_1.filterList)(listingData.departureFlights, searchData),
        }),
        component: components_1.FlightSearchBar,
    },
};
const TowingData = {
    filters: {
        getInitialFiltersValues: filter_towing_1.getInitialFilterValues,
        component: filters_1.TowingFilter,
    },
    listing: {
        initialData: types_2.emptyListData,
        loadData: index_1.loadTowingData,
        appendNewData: index_1.appendSingleListNewPageData,
        component: index_1.TowingTabListing,
    },
    search: {
        handleQueryChange: (searchData, listingData) => (0, search_1.filterList)(listingData, searchData),
        component: components_1.FlightSearchBar,
    },
};
const LinkedTabData = {
    filters: {
        getInitialFiltersValues: filter_flights_1.getInitialFilterValues,
        component: filters_1.LinkedTabFilter,
    },
    listing: {
        onItemUpdate: index_1.buildLinkedOnItemUpdate,
        initialData: types_2.emptyListData,
        loadData: index_1.loadLinkedTabData,
        appendNewData: index_1.appendSingleListNewPageData,
        component: index_1.LinkedTabListing,
    },
    search: {
        handleQueryChange: (searchData, listingData) => (0, search_1.filterLinkedList)(listingData, searchData),
        component: components_1.FlightSearchBar,
    },
};
exports.GanttData = {
    filters: {
        getInitialFiltersValues: filter_gantt_1.getGanttInitialFilterValues,
        component: filters_1.GanttFilter,
    },
    search: {
        handleQueryChange: (searchData, listingData) => listingData,
        component: components_1.FlightSearchBar,
    },
};
const flightControlTabs = {
    [types_1.AirportTabType.arrival]: ArrivalTabData,
    [types_1.AirportTabType.departure]: DepartureTabData,
    [types_1.AirportTabType.both]: BothTabData,
    [types_1.AirportTabType.linked]: LinkedTabData,
    [types_1.AirportTabType.towing]: TowingData,
    [types_1.AirportTabType.gantt]: exports.GanttData,
};
const buildInitialFilters = (user) => ({
    flight: flightControlTabs.arrival.filters.getInitialFiltersValues(user),
    towing: flightControlTabs.towing.filters.getInitialFiltersValues(user),
    gantt: flightControlTabs.gantt.filters.getInitialFiltersValues(user),
});
exports.buildInitialFilters = buildInitialFilters;
exports.default = flightControlTabs;
