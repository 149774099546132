"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useDelayedState(initialState, delay) {
    const [state, setState] = (0, react_1.useState)(initialState);
    const [delayedState, setDelayedState] = (0, react_1.useState)(state);
    const timeoutRef = (0, react_1.useRef)();
    (0, react_1.useEffect)(() => {
        if (timeoutRef.current !== undefined) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setDelayedState(state);
        }, delay);
    }, [state, delay]);
    return (0, react_1.useMemo)(() => ({
        state,
        delayedState,
        setState,
        setDelayedState,
    }), [delayedState, state]);
}
exports.default = useDelayedState;
